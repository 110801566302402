<template>
  <v-card>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <v-card-title>
      <h4>{{ objModel.name }}</h4>
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" class="mr5px" v-if="view == 'admin'" @click="edit">Edit</v-btn>
      <v-btn depressed color="info" @click="showPasswordResetForm">Reset Password</v-btn>
    </v-card-title>

    <v-card-text>

      <v-simple-table width="100px">
        <tr>
          <th>ARN CODE</th>
          <td>
            {{ objModel.code }}
          </td>
        </tr>
          <tr>
          <th>Username</th>
          <td>
            {{ getUsername(objModel.code) }}
          </td>
        </tr>
        <tr>
          <th>Name</th>
          <td>
            {{ objModel.name }}
          </td>
        </tr>
        <tr>
          <th>Address</th>
          <td>
            {{ objModel.address }}
          </td>
        </tr>

        <tr>
          <th>Pincode</th>
          <td>
            {{ objModel.pincode }}
          </td>
        </tr>
        <tr>
          <th>Phone Numbers</th>
          <td>
            {{ objModel.phone_numbers }}
          </td>
        </tr>
        <tr>
          <th>Station</th>
          <td>
            {{ objModel.station }}
          </td>
        </tr>
        <tr>
          <th>Tax Status</th>
          <td>
            {{ objModel.tax_status }}
          </td>
        </tr>
        <tr>
          <th>PAN</th>
          <td>
            {{ objModel.pan_num }}
          </td>
        </tr>

        <tr>
          <th>Tax Rate</th>
          <td>
            {{ objModel.tax_rate }}
          </td>
        </tr>

        <tr>
          <th>Exempted</th>
          <td>
            {{ objModel.exempted }}
          </td>
        </tr>

        <tr>
          <th>
            Limit
          </th>
          <td>
            {{ objModel._limit }}
          </td>
        </tr>

        <tr>
          <th>
            Service Tax
          </th>
          <td>
            {{ objModel.service_tax }}
          </td>
        </tr>

        <tr>
          <th>
            Bank Name
          </th>
          <td>
            {{ objModel.bank_name }}
          </td>
        </tr>
        <tr>
          <th>
            Bank Account Number
          </th>
          <td>
            {{ objModel.bank_account_num }}
          </td>
        </tr>
        <tr>
          <th>
            IFSC Code
          </th>
          <td>
            {{ objModel.ifsc_code }}
          </td>
        </tr>
        <tr>
          <th>
            MICR Code
          </th>
          <td>
            {{ objModel.micr_code }}
          </td>
        </tr>
        <tr>
          <th>
            Bank Branch
          </th>
          <td>
            {{ objModel.bank_branch }}
          </td>
        </tr>
        <tr>
          <th>
            Contacts
          </th>
          <td>
            {{ objModel.contacts }}
          </td>
        </tr>
        <tr>
          <th>
            Mobile Numbers
          </th>
          <td>
            {{ objModel.mobile_numbers }}
          </td>
        </tr>
        <tr>
          <th>
            Emails
          </th>
          <td>
            {{ objModel.emails }}
          </td>
        </tr>

        <tr>
          <th>
            Created At
          </th>
          <td>
            {{ formatDate(objModel.createdAt) }}
          </td>
        </tr>

        <tr>
          <th>
            Updated At
          </th>
          <td>
            {{ formatDate(objModel.updatedAt) }}
          </td>
        </tr>
      </v-simple-table>
      {{view}}
    </v-card-text>

    <v-dialog v-model="blnShowForm">
      <ArrangerForm
        @saved="handleSave"
        :view="view"
        :objModel="objModel"
      ></ArrangerForm>
    </v-dialog>

    <v-dialog v-model="blnShowPasswordResetForm" width="400">
      <ResetPasswordForm
        @saved="handlePasswordReset"
        :view="view"
        :objArranger="objModel"
      ></ResetPasswordForm>
    </v-dialog>
  </v-card>
</template>

<script>
//import api from "@/api";
//import Lots from "@/components/Lots.vue";
import ArrangerForm from "@/components/ArrangerForm.vue";
import ResetPasswordForm from "@/components/ResetPasswordForm.vue";

export default {
  name: "Arranger",
  props: { objModel: Object, view: String },
  components: {
    //Lots,
    ArrangerForm,
    ResetPasswordForm
  },
  data() {
    return {
      search: "",
      objNewModel: {},
      blnShowForm: false,
      blnShowPasswordResetForm: false,

      loading: true,
      arrItems: [],
      dialog: false,
      arrBreadcrumbs: []
    };
  },

  async created() {
    await this.refresh();

    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Arrangers",
        disabled: false,
        to: `/${this.view}/arrangers`
      }
    ];
    this.arrBreadcrumbs.push({
      text: "Arranger:" + this.objModel.name,
      disabled: false
    });
  },
  methods: {
    getResetPasswordLink() {
      if (this.view == "admin") {
        return `/admin/arrangers/${this.id}/reset-password`;
      }
    },
    handleSave() {
      this.refresh();
      this.showSnackbar("Saved Successfully");
      this.closeForm();
    },
    handlePasswordReset() {
      //this.refresh()

      this.showSnackbar("Saved Successfully");
      this.closePasswordResetForm();
    },
    edit() {
      this.blnShowForm = true;
    },
    showPasswordResetForm() {
      this.blnShowPasswordResetForm = true;
    },
    closeForm() {
      this.blnShowForm = false;
    },
    closePasswordResetForm() {
      this.blnShowPasswordResetForm = false;
    },
    async refresh() {}
  }
};
</script>
