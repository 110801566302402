<template>
  <v-card width="100%">
    <v-overlay :value="blnSaving">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <v-card-title>
      <h4>Bill Accept/Reject</h4>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="blnFormValid">
        <v-radio-group v-model="objModel._bill_status" :rules="requiredRules" class="radioGroupCol">
          <v-radio :label="`Accept`" value="A"></v-radio>
          <v-radio :label="`Reject`" value="R"></v-radio>
          <v-radio :label="`Pay`" value="P"></v-radio>
        </v-radio-group>
        <v-textarea
          v-if="objModel._bill_status == 'R'"
          required
          label="Rejection Reason"
          v-model="objModel._bill_rejection_reason"
          :rules="requiredRules"
          outlined
          height="80px"
        >
        </v-textarea>

        <template v-if="objModel._bill_status == 'P'">
          <v-textarea
            required
            label="Payment Ref"
            v-model="objModel._bill_payment_ref"
            outlined
            height="80px"
          >
          </v-textarea>
          <v-menu
            ref="blnPickDate"
            v-model="blnPickDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="objModel._bill_payment_date"
                label="Date"
                hint="YYYY-MM-DD format"
                persistent-hint
                append-icon="event"
                readonly=""
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="objModel._bill_payment_date"
              no-title
              @input="blnPickDate = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn depressed large class="w100" color="success" @click="saveModel">Save</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "BillAcceptForm",
  props: {
    view: String,
    objSummaryRecord: Object
  },
  data() {
    return {
      objModel: {},
      blnFormValid: false,
      blnSaving: false,
      blnPickDate: false
    };
  },
  async mounted() {
    console.log("mounted" + Date.now());
    let {
      _bill_status,
      _bill_rejection_reason,
      _bill_payment_ref,
      _bill_payment_date
    } = this.objSummaryRecord;
    this.objModel = {
      _bill_status,
      _bill_rejection_reason,
      _bill_payment_ref,
      _bill_payment_date
    };
    console.log(this.objModel);
  },
  async created() {
    console.log("created" + Date.now());
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        return true;
      }
      return false;
    },
    async refresh() {},
    async saveModel() {
      try {
        if (!this.$refs.form.validate()) {
          this.showSnackbar("Form is not valid");
          return;
        }
        this.blnSaving = true;
//        const objFile = this.objFile;
        const objModel = this.objModel;
        this.objModel.id = this.objSummaryRecord.id;
        this.objModel.arranger_id = this.objSummaryRecord.arranger_id;

        await api.updateSummaryRecord(
          {
            objModel
          },
          "admin"
        );
        this.$emit("saved");
      } catch (e) {
        console.log(e);
        this.showSnackbar(e.message);
      } finally {
        console.log("done");
        this.blnSaving = false;
      }
    }
  }
};
</script>
