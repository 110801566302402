<template>
  <v-card width="100%" min-height="600">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>LOTS</h4>
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="my-2" @click="download">
        <v-btn depressed color="success" dark class="ttn">
          <v-icon class="mr5px">mdi-file-excel</v-icon> Download
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table :headers="arrHeaders" :items="arrItems" :search="search">
     
      <template v-slot:item.transactions_xxx="{ item }">
        <router-link :to="'lots/' + item.lot_id + '/brokerage-records'"
          >Transactions </router-link
        >
      </template>

      <template v-slot:item.bill_xxx="{ item }">
        <template v-if="isBillUploaded(item)">
          <a target="_blank" :href="getBillLink(item)">Bill</a>
        </template>
      </template>

      <template v-slot:item.upload_bill_xxx="{ item }">
        <template v-if="!isBillAccepted(item)">
          <v-btn
            depressed
            color="secondary"
            small
            @click="showBillingForm(item)"
            >Upload</v-btn
          >
        </template>
        <template v-else> </template>
      </template>
    </v-data-table>

    <v-dialog v-model="blnShowForm" persistent max-width="600px">
      <div class="closeBtnDialog">
        <v-btn color="error" depressed text fab small dark @click="closeForm"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <BillForm
        v-if="blnShowForm"
        @saved="handleBillSave"
        :view="view"
        :objLotSummaryRecord="objLotSummaryRecord"
      ></BillForm>
    </v-dialog>
  </v-card>
</template>

<script>
import api from '@/api'
import BillForm from '@/components/BillForm.vue'
export default {
  name: 'ArrangerLots',
  props: ['view'],
  components: {
    BillForm
  },

  data () {
    return {
      search: '',
      blnShowForm: false,
      objLotSummaryRecord: {},
      loading: true,
      arrItems: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: [],
      arrHeaders: [
        {
          text: 'LotNumber',
          align: 'left',
          sortable: true,
          value: 'lot_number'
        },
        {
          text: 'Transaction',
          align: 'left',
          sortable: true,
          value: 'transactions_xxx'
        },

        {
          text: 'Alloted',
          align: 'left',
          sortable: true,
          value: 'bonds_alloted'
        },
        {
          text: 'BondsAmount',
          align: 'left',
          sortable: true,
          value: 'amount_on_application_fmt'
        },
        {
          text: 'GrossBrokerage',
          align: 'left',
          sortable: true,
          value: 'gross_brokerage_fmt'
        },
        {
          text: 'Incentive',
          align: 'left',
          sortable: true,
          value: 'incentive_below_250cr_fmt'
        },
        {
          text: 'GST',
          align: 'left',
          sortable: true,
          value: 'gst_fmt'
        },
        {
          text: 'Total',
          align: 'left',
          sortable: true,
          value: 'total_commission_fmt'
        },

        {
          text: 'TotalBillAmount',
          align: 'left',
          sortable: true,
          value: '_total_bill_amount_fmt'
        },
        {
          text: 'BillNumber',
          align: 'left',
          sortable: true,
          value: '_bill_number'
        },
        {
          text: 'Bill',
          align: 'left',
          sortable: true,
          value: 'bill_xxx'
        },

        {
          text: 'Upload Bill',
          align: 'left',
          sortable: true,
          value: 'upload_bill_xxx'
        },
        {
          text: 'BillStatus',
          align: 'left',
          sortable: true,
          value: '_bill_status_fmt'
        }
      ]
    }
  },
  async created () {
    this.refresh()

    this.arrBreadcrumbs = [
      {
        text: 'Home',
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: 'Lots',
        disabled: true
      }
    ]
  },
  methods: {
    download () {
      const strFileName = 'AllLots'
      let arrXLSHeaders = this._getXLSHeaders(this.arrHeaders)

      this.downloadXLS(this.arrItems, arrXLSHeaders, strFileName)
    },
    getBillLink (objSummaryRecord) {
      let strUrl = api.getServerUrl()
      return strUrl + 'uploads/' + objSummaryRecord._bill_filename
    },
    isBillUploaded (objSummaryRecord) {
      if (objSummaryRecord._bill_number) {
        return true
      }
      return false
    },
    isBillAccepted (objSummaryRecord) {
      const arrAccStatus = ['A', 'P']
      //accepted or paid
      if (arrAccStatus.includes(objSummaryRecord._bill_status)) {
        return true
      }
      return false
    },
    handleBillSave () {
      this.showSnackbar('Bill Uploaded')
      this.blnShowForm = false
      this.refresh()
    },
    showBillingForm (objLotSummaryRecord) {
      //let objSummaryRecord = obj.summary_records[0]
      this.objLotSummaryRecord = { ...objLotSummaryRecord }
      this.blnShowForm = true
    },
    closeForm () {
      this.blnShowForm = false
    },

    async refresh () {
      this.loading = true
      try {
        this.arrItems = await api.getArrangerLots()
        this.arrItems = this.arrItems.map(objLot => {
          let objSummaryRecord = objLot.summary_records[0]
          objSummaryRecord.lot_id = objLot.id

          objSummaryRecord.lot_number = objLot.lot_number
          objSummaryRecord = this.formatSummaryRecord(objSummaryRecord)

          return objSummaryRecord
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
