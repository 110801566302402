<template>
  <v-card width="100%">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="deep-purple accent-4"
    ></v-progress-linear>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <v-overlay :value="blnSaving">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      File Uploading....
    </v-overlay>
    <div>
      <v-breadcrumbs :items="arrBreadcrumbs">
        <template v-slot:item="props">
          <v-breadcrumbs-item
            :to="props.item.to"
            :class="[props.item.disabled]"
            exact
          >
            {{ props.item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-card-text>
    <v-form ref="form" v-model="blnFormValid">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              autocomplete="off"
              v-model="objModel.lot_number"
              label="Lot Number"
              :rules="lotNumberRules"
              required
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <template>
              <v-file-input
                accept=".xlsx"
                label="File input"
                :rules="sheetRules"
                v-model="objSheetFile"
                prepend-icon=""
                append-icon="mdi-attachment"
                outlined
              >
              </v-file-input>
            </template>
          </v-col>
        </v-row>
        <v-btn
          :disabled="!blnFormValid"
          color="success"
          class="mr-4"
          @click="submit"
          depressed
        >
          Submit
        </v-btn>
    </v-form>

    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
import api from '@/api'
import XLSX from 'xlsx'

export default {
  props: {
    id: {
      type: String
    },
    owner: {
      type: Object
    }
  },
  computed: {},
  data () {
    return {
      objModel: {},
      objSheetFile: null,
      search: '',
      blnFormValid: false,
      blnSaving: false,
      headers: [
        {
          text: 'Date',
          align: 'left',
          sortable: true,
          value: 'PostingDate'
        },
        {
          text: 'Charge',
          align: 'left',
          sortable: true,
          value: 'charge'
        },
        {
          text: 'Credit',
          align: 'left',
          sortable: true,
          value: 'credit'
        },

        /* {
          text: 'Description',
          align: 'left',
          sortable: true,
          value: 'Description'
        },
        {
          text: 'Amount',
          align: 'left',
          sortable: true,
          value: 'Amount'
        },*/
        {
          text: 'Balance',
          align: 'left',
          sortable: true,
          value: 'Balance'
        },
        {
          text: 'TAcctID',
          align: 'left',
          sortable: true,
          value: 'TAcctID'
        }
      ],
      loading: false,
      arrItems: [],

      lotNumberRules: [
        v => !!v || 'Lot number is required',
        v =>
          (v && v.length <= 10) || 'Lot number must be less than 10 characters'
      ],
      sheetRules: [
        value => {
          try {
            if (this.objSheetFile.name) {
              return true
            } else {
              return 'Required.'
            }
          } catch (e) {
            return 'Required.'
          }
        }
      ],
      arrSummaryRecords: [],
      objUnitAccount: {},
      objContact: {},
      model: {},
      dialog: false,
      arrBreadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          to: '/admin/home'
        },
        {
          text: 'Lots',
          disabled: false,
          to: '/admin/lots'
        },
        {
          text: 'Upload',
          disabled: true
        }
      ],
      blnContactLoaded: false
    }
  },
  async created () {
    // this.refresh()
  },
  methods: {
    async submit () {
      if (!this.$refs.form.validate()) {
        this.showSnackbar('Form is not valid')
        return
      }
      try {
        const objSheetFile = this.objSheetFile
        const objModel = this.objModel
        console.log(objSheetFile)
        this.blnSaving = true
        let objResponse = await api.createLot({
          objSheetFile,
          objModel
        })

        if(!objResponse.blnOK){
          throw new Error(objResponse.strMessage);
        }

       this.$router.replace({name:"AdminLots"});


        
      } catch (e) {
        this.showSnackbar(e.message)
      } finally {
        this.blnSaving = false
      }
    }
  }
}
</script>
