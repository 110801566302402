<template>
  <v-card width="100%" min-height="500">
    <v-card-title>
      Admin Home
      <v-spacer></v-spacer>
    </v-card-title>
    <v-simple-table>
      <tbody>
        <!-- <tr>
          <td width="200"><strong>Vendor</strong></td>
          <td>{{ objInvoice.Payee }}</td>
        </tr>-->
      </tbody>
    </v-simple-table>
    <br /><br />
  </v-card>
</template>

<script>
// @ is an alias to /src
import api from "@/api";

export default {
  name: "home",
  data: () => ({
    menu: false,
    loading: false,
    arrItems: []
  }),
  async created() {
    this.refresh();
  },
  components: {},
  methods: {
    async refresh() {
      this.loading = true;
      this.arrItems = await api.getAdminHome();
      this.loading = false;
    }
  }
};
</script>
