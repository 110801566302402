<template>
  <v-card width="100%" min-height="400">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>Make Advance Payment</h4>
    </v-card-title>

    <v-card-text>
      <br />
      <br />
      <h3>Select Arranger</h3>
      <div>
        <v-spacer>
          <v-autocomplete
            :items="arrArrangers"
            item-text="name"
            :return-object="blnReturnObject"
            v-model="objArranger"
            @change="handleArrangerChange"
          ></v-autocomplete>
        </v-spacer>
      </div>

      <h2>LOTS</h2>
      <v-spacer>
        <div class="tableSearch mr10px">
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            rounded
            dense
            hide-details
          ></v-text-field>
        </div>
      </v-spacer>
      <v-data-table :headers="headers" :items="arrItems" :search="search">
        <template v-slot:item.lot_number="{ item }">
          {{ item.lot_number }}
        </template>
        <template v-slot:item.transactions="{ item }">
          <router-link :to="'lots/' + item.lot_id + '/brokerage-records'"
            >Transactions</router-link
          >
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:item.TotalBillAmount="{ item }">
          {{ item._total_bill_amount }}
        </template>
        <template v-slot:item.BillNumber="{ item }">
          {{ item._bill_number }}
        </template>
      </v-data-table>

      <v-btn @click="showPaymentForm" :disabled="isPayButtonDisabled()"
        >Pay</v-btn
      >

      <v-dialog v-model="blnShowForm" persistent max-width="600px">
        <v-btn @click="closeForm">Close </v-btn>
        <AdvancePaymentForm
          v-if="blnShowForm"
          @saved="handleAdvancePaymentSave"
          :view="view"
          :objArranger="objArranger"
        ></AdvancePaymentForm>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import AdvancePaymentForm from "@/components/AdvancePaymentForm.vue";
export default {
  components: { AdvancePaymentForm },
  data() {
    return {
      search: "",
      blnClearable: true,
      blnReturnObject: true,
      blnShowForm: false,
      objArranger: {},
      arrArrangers: [],
      loading: true,
      arrItems: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: []
    };
  },
  async created() {
    this.refresh();

    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/admin/home`
      },
      {
        text: "Make Advance Payment",
        disabled: true
      }
    ];

    let headers = [
      {
        text: "LotNumber",
        align: "left",
        sortable: true,
        value: "lot_number"
      },

      {
        text: "BondsAlloted",
        align: "left",
        sortable: true,
        value: "bonds_alloted"
      },
      {
        text: "BondsAmount",
        align: "left",
        sortable: true,
        value: "amount_on_application"
      },
      {
        text: "GrossBrokerage",
        align: "left",
        sortable: true,
        value: "gross_brokerage"
      },
      {
        text: "Incentive",
        align: "left",
        sortable: true,
        value: "incentive_below_250cr"
      },
      {
        text: "TotalCommission",
        align: "left",
        sortable: true,
        value: "total_commission"
      },
      {
        text: "GST",
        align: "left",
        sortable: true,
        value: "gst"
      },
      {
        text: "NetBrokerage",
        align: "left",
        sortable: true,
        value: "net_brokerage"
      },

      {
        text: "TotalBillAmount",
        align: "left",
        sortable: true,
        value: "_total_bill_amount"
      },
      {
        text: "Status",
        align: "left",
        sortable: true,
        value: "_bill_status"
      },
      {
        text: "BillNumber",
        align: "left",
        sortable: true,
        value: "_bill_number"
      }
    ];

    this.headers = headers;
  },
  methods: {
    isPayButtonDisabled() {
      if (!this.objArranger || !this.objArranger.id) {
        return true;
      }
      return false;
    },
    isBillUploaded() {},
    async handleArrangerChange() {
      await this.getSelectedArrangerLots();
    },
    handleAdvancePaymentSave() {
      this.showSnackbar("Payment Record Created!");
      this.blnShowForm = false;
      this.$router.replace("/admin/advance-payments");
    },
    //showPaymentForm (obj) {
    showPaymentForm() {
      this.blnShowForm = true;
    },
    closeForm() {
      this.blnShowForm = false;
    },
    get(obj, strPropName) {
      if (strPropName in obj) {
        //console.log('getting', strPropName)
        //console.log('returning', obj[strPropName])
        return obj[strPropName];
      }

      return "n/a";
    },
    async refresh() {
      this.loading = true;
      try {
        this.arrArrangers = await api.getArrangers();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async getSelectedArrangerLots() {
      if (!this.objArranger) {
        return;
      }
      const intArrangerId = this.objArranger.id;
      this.loading = true;
      try {
        let obj = await api.getAdminArrangerLots(intArrangerId);
        //TODO:Can we have this blnOK check in the API itself
        //won't have to duplicate it over and over again
        if (!obj.blnOK) {
          throw new Error(obj.strMessage);
        }
        obj.arr = obj.arr.map(objItem => {
          objItem = this.formatSummaryRecord(objItem);
          objItem.lot_number = "n/a";
          if (objItem.lot) {
            objItem.lot_number = objItem.lot.lot_number;
          }
          return objItem;
        });
        this.arrItems = obj.arr;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
