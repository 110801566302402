<style lang="sass">
$color-pack: false
@import './assets/style.scss'
</style>

<template>
  <v-app id="inspire" class="customTheme">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  computed: {
    layout() {
      return "";
      /*if (this.$store.state.blnAuthenticated) {
        return 'DefaultLayout'
      }
      return 'BareLayout';*/
    }
  },
  components: {},
  props: {
    source: String
  },
  data: () => ({
    drawer: null
  })
};
</script>
