import moment from "moment";

export default {
  formatDate(strDate) {
    let objDate = moment(strDate, "YYYY-MM-DD");
    if (!objDate.isValid()) {
      return "";
    }
    return objDate.format("YYYY-MM-DD");
  }
};
