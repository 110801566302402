import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    blnLoading: false,

    objArranger: {},
    objAdmin: {}
  },
  mutations: {
    startLoader(state) {
      state.blnLoading = true;
    },
    stopLoader(state) {
      state.blnLoading = false;
    },
    setArranger(state, obj) {
      //console.log("SETTING ARRANGER", obj);
      state.objArranger = obj;
    },
    setAdmin(state, obj) {
      console.log("SETTING ADMIN", obj);
      state.objAdmin = obj;
    }
  }
});

export default store;
