<template>
  <v-card width="100%" min-height="600">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>LOT:{{ obj.lot_number }} SUMMARY RECORDS</h4>
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="my-2" @click="download">
        <v-btn depressed color="success" dark class="ttn">
          <v-icon class="mr5px">mdi-file-excel</v-icon> Download
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="arrHeaders" :items="arrItems" :search="search">
        <template v-slot:item.lot_id="{ item }">
          <router-link :to="'lots/' + item.lot_id">{{
            item.lot.lot_number
          }}</router-link>
        </template>

        <template v-slot:item.arranger="{ item }">
          {{ item.arranger.name }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "Arrangers",
  props: ["lotId", "view"],

  data() {
    return {
      search: "",
      arrHeaders: [
        {
          text: "id",
          align: "left",
          sortable: true,
          value: "id"
        },
        /*{
          text: 'ARNCode',
          align: 'left',
          sortable: true,
          value: 'arn_code'
        },*/
        {
          text: "Arranger",
          align: "left",
          sortable: true,
          value: "arranger_name"
        },
        {
          text: "Apps",
          align: "left",
          sortable: true,
          value: "appins"
        },
        {
          text: "BondsApplied",
          align: "left",
          sortable: true,
          value: "bonds_applied"
        },
        {
          text: "AmountOnApplication",
          align: "left",
          sortable: true,
          value: "amount_on_application_fmt"
        },
        {
          text: "BondsAlloted",
          align: "left",
          sortable: true,
          value: "bonds_alloted"
        },
        {
          text: "AmountAdjusted",
          align: "left",
          sortable: true,
          value: "amount_adjusted_fmt"
        },
        {
          text: "GrossBrokerage",
          align: "left",
          sortable: true,
          value: "gross_brokerage_fmt"
        },
        {
          text: "Incentive",
          align: "left",
          sortable: true,
          value: "incentive_below_250cr_fmt"
        },
        {
          text: "TotalCommission",
          align: "left",
          sortable: true,
          value: "total_commission_fmt"
        },
        {
          text: "GST",
          align: "left",
          sortable: true,
          value: "gst_fmt"
        },
        {
          text: "NetBrokerage",
          align: "left",
          sortable: true,
          value: "net_brokerage_fmt"
        }
        /*
        {
          text: 'CreatedAt',
          align: 'left',
          sortable: true,
          value: 'createdAt'
        }
*/
      ],
      loading: true,
      arrItems: [],
      arrItems2: [],
      obj: {},
      dialog: false,
      arrBreadcrumbs: []
    };
  },
  async created() {
    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Lots",
        disabled: false,
        to: `/${this.view}/lots`
      },
      {
        text: "Lot",
        disabled: true
      }
    ];

    this.refresh();
  },
  methods: {
    download() {
      const strFileName = "Lot" + this.obj.lot_number + "SummaryRecords";
      let arrXLSHeaders = this._getXLSHeaders(this.arrHeaders);

      this.downloadXLS(this.arrItems, arrXLSHeaders, strFileName);
    },
    getRecordLink(objItem) {
      const lotId = this.lotId;
      const id = objItem.id;
      const str = `/${this.view}/lots/${lotId}/records/${id}`;
      return str;
    },

    getViewLink(objItem) {
      return objItem.UnitAddress.OneLine;
    },
    async refresh() {
           this.loading = true;
      try {
        let intLotId = this.lotId;
        let objLot;
        if (this.view == "arranger") {
          objLot = await api.getArrangerLotSummaryRecords(intLotId);
        } else {
          objLot = await api.getAdminLotSummaryRecords(intLotId);
        }
        this.obj = objLot;
        this.arrItems = objLot.summary_records;
        this.arrItems2 = JSON.parse(JSON.stringify(this.arrItems));

        this.arrItems = this.arrItems.map(objItem => {
          objItem = this.formatLot(objItem);
          objItem.arranger_name = "n/a";
          try {
            objItem.arranger_name = objItem.arranger.name;
          } catch (e) {
            console.log(e);
          }
          try {
            objItem.lot_number = objItem.lot.number;
          } catch (e) {
            console.log(e);
          }
          return objItem;
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
