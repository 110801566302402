<template>
  <v-card width="100%" min-height="600">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>Record</h4>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-simple-table>
      <tr>
        <th class="w25">
          ARN CODE
        </th>
        <td class="w25">
          {{ objRecord.arn_code }}
        </td>
        <th class="w25">
          App. Number
        </th>
        <td class="w25">
          {{ objRecord.appl_no }}
        </td>
      </tr>

      <tr>
        <th>
          E-Form?
        </th>
        <td>
          {{ objRecord.e_form }}
        </td>
        <th>
          Credit Date
        </th>
        <td>
          {{ objRecord.credit_date }}
        </td>
      </tr>

      <tr>
        <th>
          Applicant Name
        </th>
        <td>
          {{ objRecord.appl_name }}
        </td>
        <th>
          Bonds
        </th>
        <td>
          {{ objRecord.bonds }}
        </td>
      </tr>

      <tr>
        <th>
          Amount Allotment
        </th>
        <td>
          {{ objRecord.amount_allotment }}
        </td>
        <th>
          Sub Broker Code
        </th>
        <td>
          {{ objRecord.sub_broker_code }}
        </td>
      </tr>

      <tr>
        <th>
          DD/Cheque Number
        </th>
        <td>
          {{ objRecord.dd_chq_no }}
        </td>
        <th>
          Payment Date
        </th>
        <td>
          {{ objRecord.payment_date }}
        </td>
      </tr>

      <tr>
        <th>
          PAN Number
        </th>
        <td>
          {{ objRecord.pan_no }}
        </td>
        <th>
          Cheque Number
        </th>
        <td>
          {{ objRecord.cheque_no }}
        </td>
      </tr>

      <tr>
        <th>
          Address
        </th>
        <td>
          {{ objRecord.add_1 }},<br />
          {{ objRecord.add_2 }},<br />
          {{ objRecord.add_3 }},<br />
          {{ objRecord.pincode }},<br />
        </td>
        <th>
          L. Folio
        </th>
        <td>
          {{ objRecord.l_folio }}
        </td>
      </tr>

      <tr>
        <th>
          LOT Number
        </th>
        <td>
          {{ objRecord.lot_number }}
        </td>
        <th>
          Created At
        </th>
        <td>
          {{ objRecord.createdAt }}
        </td>
      </tr>
    </v-simple-table>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "Record",
  props: ["lotId", "recordId", "view"],

  data() {
    return {
      search: "",
      objRecord: {},
      headers: [
        {
          text: "id",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "arn_code",
          align: "left",
          sortable: true,
          value: "arn_code"
        },
        {
          text: "appl_no",
          align: "left",
          sortable: true,
          value: "appl_no"
        },
        {
          text: "e_form",
          align: "left",
          sortable: true,
          value: "e_form"
        },
        {
          text: "credit_date",
          align: "left",
          sortable: true,
          value: "credit_date"
        },
        {
          text: "appl_name",
          align: "left",
          sortable: true,
          value: "appl_name"
        },
        {
          text: "bonds",
          align: "left",
          sortable: true,
          value: "bonds"
        },
        {
          text: "amount_allotment",
          align: "left",
          sortable: true,
          value: "amount_allotment"
        },
        {
          text: "sub_broker_code",
          align: "left",
          sortable: true,
          value: "sub_broker_code"
        },
        {
          text: "dd_chq_no",
          align: "left",
          sortable: true,
          value: "dd_chq_no"
        },
        {
          text: "payment_date",
          align: "left",
          sortable: true,
          value: "payment_date"
        },
        {
          text: "pan_no",
          align: "left",
          sortable: true,
          value: "pan_no"
        },
        {
          text: "cheque_no",
          align: "left",
          sortable: true,
          value: "cheque_no"
        },
        {
          text: "add_1",
          align: "left",
          sortable: true,
          value: "add_1"
        },
        {
          text: "add_2",
          align: "left",
          sortable: true,
          value: "add_2"
        },
        {
          text: "add_3",
          align: "left",
          sortable: true,
          value: "add_3"
        },
        {
          text: "pincode",
          align: "left",
          sortable: true,
          value: "pincode"
        },
        {
          text: "l_folio",
          align: "left",
          sortable: true,
          value: "l_folio"
        },

        {
          text: "Created At",
          align: "left",
          sortable: true,
          value: "lot.createdAt"
        }
      ],
      loading: true,
      arrItems: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: []
    };
  },
  async created() {
    await this.refresh();
    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Lots",
        disabled: false,
        to: `/${this.view}/lots`
      }
    ];
    this.arrBreadcrumbs.push({
      text: "Lot#" + this.objRecord.lot_number + " Records",
      disabled: false,
      to: `/${this.view}/lots/` + this.objRecord.lot_id + "/brokerage-records"
    });

    this.arrBreadcrumbs.push({
      text: "Record#" + this.objRecord.id,
      disabled: true
    });
  },
  methods: {
    getUnitAddress(objItem) {
      console.log(objItem);
      return objItem.UnitAddress.OneLine;
    },
    getViewLink(objItem) {
      
      return objItem.UnitAddress.OneLine;
    },
    async refresh() {
           this.loading = true;
      try {
        let intRecordId = this.recordId;
        let intLotId = this.lotId;
        if (this.view == "arranger") {
          this.objRecord = await api.getArrangerLotRecord(
            intLotId,
            intRecordId
          );
        } else {
          
          this.objRecord = await api.getAdminLotRecord(intLotId, intRecordId);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
