import XLSX from 'xlsx'
import moment from 'moment'

export default {
  data () {
    return {
      strSnackbarText: '',
      blnSnackbar: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      usernameRules: [
        v => !!v || 'Username is required',
        v => v.length == 5 || 'Username must be 5 chars long'
      ],
      requiredRules: [v => !!v || 'Required'],
      specialCharRule: v => {
        let regex = /[!@#$%^&*()":{}|<>]/
        var bln = regex.test(v)
        if (!bln) {
          return true
        }
        return 'Data must not have special characters !@#$%^&*(),.?":{}|<>'
      },
      positiveNumberRule: v => {
        var intNumber = new Number(v)

        if (intNumber > 0) {
          return true
        }

        return 'Number must be greater than 0'
      },
      requiredRule: v => !!v || 'Required',
      passwordRules: [
        v => !!v || 'Required',
        v =>
          (v.length >= 8 && v.length <= 12) ||
          'Password must be between 8 to 12 chars long',
        v => {
          let regex = /[0-9]/
          var bln = regex.test(v)
          if (bln) {
            return true
          }
          return 'Password must have at least 1 digit/number'
        },
        v => {
          let regex = /[A-Z]/
          var bln = regex.test(v)
          if (bln) {
            return true
          }
          return 'Password must have at least 1 capital letter A-Z'
        },
        v => {
          let regex = /[a-z]/
          var bln = regex.test(v)
          if (bln) {
            return true
          }
          return 'Password must have at least 1 small letter a-z'
        },
        v => {
          let regex = /[!@#$%^&*(),.?":{}|<>]/
          var bln = regex.test(v)
          if (bln) {
            return true
          }
          return 'Password must have at least 1 special character '
        },
       
      ]
    }
  },
  methods: {
    formatMoney (strAmount) {
      //100000 becomes 1,00,000 etc
      let objNumber = Number(strAmount)
      if (isNaN(objNumber)) {
        return ''
      }
      return objNumber.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      })
    },

    //basically lot and summary record have similar
    //attributes
    formatLot (obj) {
      return this.formatSummaryRecord(obj)
    },
    formatSummaryRecord (obj) {
      obj.brokerwise = ''
      obj.applicationwise = ''
      obj.gst_fmt = this.formatMoney(obj.gst)
      obj.amount_on_application_fmt = this.formatMoney(
        obj.amount_on_application
      )
      obj.gross_brokerage_fmt = this.formatMoney(obj.gross_brokerage)
      obj.incentive_below_250cr_fmt = this.formatMoney(
        obj.incentive_below_250cr
      )

      obj.amount_adjusted_fmt = this.formatMoney(obj.amount_adjusted)
      obj.total_commission_fmt = this.formatMoney(obj.total_commission)
      obj.net_brokerage_fmt = this.formatMoney(obj.net_brokerage)

      const arrNotOkStatus = ['N', '', null]
      if (arrNotOkStatus.includes(obj._bill_status)) {
        //obj._total_bill_amount = "XX";
      } else {
        //only format if the bill status is NOT new and null
        obj._total_bill_amount_fmt = this.formatMoney(obj._total_bill_amount)
      }

      obj._bill_status_fmt = this.formatBillStatus(obj._bill_status)
      if (obj._bill_status == 'R') {
        obj._bill_status_fmt += `\r\n(${obj._bill_rejection_reason})`
      }
      obj._total_bill_amount_fmt = this.formatMoney(obj._total_bill_amount)

      obj.__advance_amount = ''
      if (obj.advance_payment) {
        obj.__advance_paid = 'Yes'
        obj.__advance_amount = obj.advance_payment.amount
        obj.__advance_amount_fmt = this.formatMoney(obj.__advance_amount)
        obj.isSelectable = false
      } else {
        obj.__advance_paid = 'No'
      }

      obj.createdAt_fmt = this.formatDate(obj.createdAt)

      return obj
    },

    formatDateTime (str) {
      const obj = moment(str)
      if (obj.isValid()) {
        return obj.format('DD-MM-YYYY h:mm a')
      }
    },
    formatBill (obj) {
      //obj.bonds_amount = this.formatMoney(obj.bonds_amount);
      obj.amount_on_application_fmt = this.formatMoney(
        obj.amount_on_application
      )
      obj.gross_brokerage_fmt = this.formatMoney(obj.gross_brokerage)
      obj.incentive_below_250cr_fmt = this.formatMoney(
        obj.incentive_below_250cr
      )
      obj.gst_fmt = this.formatMoney(obj.gst)
      obj.amount_adjusted_fmt = this.formatMoney(obj.amount_adjusted)
      obj.total_commission_fmt = this.formatMoney(obj.total_commission)
      obj.net_brokerage_fmt = this.formatMoney(obj.net_brokerage)
      obj._total_bill_amount_fmt = this.formatMoney(obj._total_bill_amount)
      obj._bill_status_fmt = this.formatBillStatus(obj._bill_status)
      obj._bill_upload_date_fmt = this.formatDate(obj._bill_upload_date)

      return obj
    },
    formatBrokerageRecord (obj) {
      obj.amount_allotment_fmt = this.formatMoney(obj.amount_allotment)
      obj.payment_date_fmt = this.formatDate(obj.payment_date)
      obj.credit_date_fmt = this.formatDate(obj.credit_date)

      return obj
    },
    formatDate (str) {
      const obj = moment(str, 'YYYY-MM-DD')
      if (obj.isValid()) {
        return obj.format('DD-MM-YYYY')
      }
      return str
    },
    formatAdvancePayment (obj) {
      obj.amount_fmt = this.formatMoney(obj.amount)
      obj.bill_status_fmt = this.formatBillStatus(obj._bill_status)
      obj.createdAt_fmt = this.formatDate(obj.createdAt)
      obj.payment_date_fmt = this.formatDate(obj.payment_date)

      return obj
    },
    formatBillStatus (str) {
      let strStatus = str
      switch (str) {
        case 'N':
          strStatus = 'New'
          break
        case 'A':
          strStatus = 'Accepted'
          break
        case 'R':
          strStatus = 'Rejected'
          break

        case 'P':
          strStatus = 'Paid'
          break
      }
      return strStatus
    },
    showSnackbar (strText) {
      this.blnSnackbar = true
      this.strSnackbarText = strText
    },
    getBillStatus (strBillStatus) {
      switch (strBillStatus) {
        case 'N':
          return 'New'
        case 'A':
          return 'Accepted'
        case 'R':
          return 'Rejected'
        case 'P':
          return 'Paid'
      }
      return strBillStatus
    },
    downloadXLS (arrItems, arrHeaders, strFileName) {
      const arrRows = arrItems
      const arrHeader = arrHeaders.map(obj => obj.text)
      let arrAllRows = [arrHeader]

      //console.log('arrAllRows', arrAllRows)

      let arrTemp = arrRows.map(objRow => {
        //console.log(objRow)
        let arr = []

        for (let objHead of arrHeaders) {
          arr.push(objRow[objHead.value])
        }
        return arr
      })

      //console.log('arrTemp', arrTemp)

      /* original data */
      strFileName = strFileName + '.xlsx'
      arrTemp = arrAllRows.concat(arrTemp)
      //console.log('Final arrTemp', arrTemp)

      var strWorkSheetName = 'Sheet1'

      if (typeof console !== 'undefined') console.log(new Date())
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(arrTemp)

      /* add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, strWorkSheetName)

      /* write workbook */
      if (typeof console !== 'undefined') console.log(new Date())
      XLSX.writeFile(wb, strFileName)
      if (typeof console !== 'undefined') console.log(new Date())
    },
    _getXLSHeaders (arrHeaders) {
      //console.log("GOT", arrHeaders);
      const arrXLSHeaders = []
      //make a copy
      try {
        arrHeaders = JSON.parse(JSON.stringify(arrHeaders))
      } catch (e) {}

      arrHeaders.forEach(obj => {
        try {
          let strHeader = obj.value
          obj.value = strHeader.replace(/_fmt$/, '')
          if (strHeader.search(/_xxx$/) != -1) {
            return
          }
          arrXLSHeaders.push(obj)
        } catch (e) {
          console.log(e)
        }
      })
      //console.log("returning ", arrXLSHeaders);
      return arrXLSHeaders
    },

    getUsername (strCode) {
      const intCode = parseInt(strCode, 10)
      if (isNaN(strCode)) {
        return 'error' + strCode
      }

      var strCode = intCode + ''
      strCode = strCode.padStart(5, '0')
      return strCode
    }
  }
}
