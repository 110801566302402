<template>
  <v-card width="100%" min-height="600">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      LOT
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
    </v-card-title>
    <v-data-table :headers="headers" :items="arrItems" :search="search">
      <template v-slot:item.lot_id="{ item }">
        <router-link :to="'lots/' + item.lot_id">{{
          item.lot.lot_number
        }}</router-link>
      </template>
      <template v-slot:item.id="{ item }">
        <router-link :to="getRecordLink(item)">Record </router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "Arrangers",
  props: ["lotId", "view"],

  data() {
    return {
      search: "",
      headers: [
        {
          text: "id",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "arn_code",
          align: "left",
          sortable: true,
          value: "arn_code"
        },
        {
          text: "appl_no",
          align: "left",
          sortable: true,
          value: "appl_no"
        },
        {
          text: "e_form",
          align: "left",
          sortable: true,
          value: "e_form"
        },
        {
          text: "credit_date",
          align: "left",
          sortable: true,
          value: "credit_date"
        },
        {
          text: "appl_name",
          align: "left",
          sortable: true,
          value: "appl_name"
        },
        {
          text: "bonds",
          align: "left",
          sortable: true,
          value: "bonds"
        },
        {
          text: "amount_allotment",
          align: "left",
          sortable: true,
          value: "amount_allotment"
        },
        {
          text: "sub_broker_code",
          align: "left",
          sortable: true,
          value: "sub_broker_code"
        },
        {
          text: "dd_chq_no",
          align: "left",
          sortable: true,
          value: "dd_chq_no"
        },
        {
          text: "payment_date",
          align: "left",
          sortable: true,
          value: "payment_date"
        },
        {
          text: "pan_no",
          align: "left",
          sortable: true,
          value: "pan_no"
        },
        {
          text: "cheque_no",
          align: "left",
          sortable: true,
          value: "cheque_no"
        },
        {
          text: "add_1",
          align: "left",
          sortable: true,
          value: "add_1"
        },
        {
          text: "add_2",
          align: "left",
          sortable: true,
          value: "add_2"
        },
        {
          text: "add_3",
          align: "left",
          sortable: true,
          value: "add_3"
        },
        {
          text: "pincode",
          align: "left",
          sortable: true,
          value: "pincode"
        },
        {
          text: "l_folio",
          align: "left",
          sortable: true,
          value: "l_folio"
        },

        {
          text: "Created At",
          align: "left",
          sortable: true,
          value: "lot.createdAt"
        }
      ],
      loading: true,
      arrItems: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: []
    };
  },
  async created() {
    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Lots",
        disabled: false,
        to: `/${this.view}/lots`
      },
      {
        text: "Lot",
        disabled: true
      }
    ];

    this.refresh();
  },
  methods: {
    getRecordLink(objItem) {
      const lotId = this.lotId;
      const id = objItem.id;
      const str = `/${this.view}/lots/${lotId}/records/${id}`;
      return str;
    },
    getUnitAddress(objItem) {
      console.log(objItem);
      return objItem.UnitAddress.OneLine;
    },
    getViewLink(objItem) {
      return objItem.UnitAddress.OneLine;
    },
    async refresh() {
           this.loading = true;
      try {
        let intLotId = this.lotId;
        let objLot;
        if (this.view == "arranger") {
          objLot = await api.getArrangerLotRecords(intLotId);
        } else {
          objLot = await api.getAdminLotRecords(intLotId);
        }

        console.log(objLot);
        this.arrItems = objLot.brokerage_records;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
