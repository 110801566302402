<!-- this page basically shows all summary records for which bills have 
been uploaded -->
<template>
  <v-card width="100%" min-height="400">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>Advance Payments</h4>
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>

      <!--<v-btn to="/admin/make-advance-payment">Make Advance Payment</v-btn>-->

      <v-btn depressed color="primary" class="ttn mr10px" to="/admin/make-advance-payment-lot-wise">Make Advance Payment (LotWise)</v-btn>

      <div class="my-2" @click="download">
        <v-btn depressed color="success" dark class="ttn">
          <v-icon class="mr5px">mdi-file-excel</v-icon> Download
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table :headers="arrHeaders" :items="arrItems" :search="search">
      <template v-slot:item.Process="{ item }">
        <v-btn @click="showBillingForm(item)">Process</v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "AdminAdvancePayments",
  props: ["view"],
  components: {},

  data() {
    return {
      search: "",
      blnShowForm: false,
      loading: true,
      arrItems: [],
      arrRawItems: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: [],
      arrHeaders: [
        {
          text: "Lot",
          align: "left",
          sortable: true,
          value: "lot_number"
        },

        {
          text: "Arranger",
          align: "left",
          sortable: true,
          value: "arranger_name"
        },
        {
          text: "Amount",
          align: "left",
          sortable: true,
          value: "amount_fmt"
        },

        {
          text: "PaymentDate",
          align: "left",
          sortable: true,
          value: "payment_date_fmt"
        },
        {
          text: "PaymentRef",
          align: "left",
          sortable: true,
          value: "payment_ref"
        },
        {
          text: "CreatedAt",
          align: "left",
          sortable: true,
          value: "createdAt_fmt"
        }
      ]
    };
  },
  async created() {
    this.refresh();

    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Advance Payments",
        disabled: true
      }
    ];
  },
  methods: {
    download() {
      const strFileName = "AdvancePayments";
      const arrHeaders = this._getXLSHeaders(this.arrHeaders);
      this.downloadXLS(this.arrItems,arrHeaders, strFileName);
    },
    async refresh() {
           this.loading = true;
      try {
        if (this.view == "admin") {
          let obj = await api.getAdminAdvancePayments();
          this.arrItems = obj.arr;
          this.arrItems = this.arrItems.map(obj => {
            try {
              obj.arranger_name = obj.summary_record.arranger.name;
            } catch (e) {
              obj.arranger_name = "n/a";
            }
            try {
              obj.lot_number = obj.summary_record.lot.lot_number;
            } catch (e) {
              obj.lot_number = "n/a";
            }
            return obj;
          });

          this.arrItems = this.arrItems.map(obj => {
            obj = this.formatAdvancePayment(obj);
            return obj;
          });
        } else if (this.view == "arranger") {
          return;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
