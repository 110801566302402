<template>
  <v-card>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <v-card-title>
      <h4 class="lh1">Reset Password for <br />{{ objArranger.name }}</h4>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pb0px mt30px">
      <v-form ref="form" v-model="blnFormValid">
        <v-text-field
          v-if="view == 'arranger'"
          label="Old Password"
          :rules="requiredRules"
          v-model="objModel.old_password"
          type="password"
          outlined
          class="mb15px"
        >
        </v-text-field>
        <div>
          <p>
            Passwords must be at least 8 characters long, with at least 1 number
            [0-9] and 1 capital letter [A-Z]
          </p>
        </div>

        <v-text-field
          required
          :rules="passwordRules"
          label="New Password"
          v-model="objModel.new_password"
          outlined
          class="mb15px"
        >
        </v-text-field>

        <v-text-field
          :rules="passwordRules"
          required
          label="Confirm Password"
          v-model="objModel.new_password_repeat"
          outlined
          class="mb15px"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <div class="pl8px pr8px w100">
        <v-btn depressed large color="success" class="w100" @click="saveModel"
          >Save</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import myMixin from "@/mixins/index.js";
import api from "@/api";

export default {
  name: "ResetPassword",
  mixins: [myMixin],
  props: { view: String, objArranger: Object },

  data() {
    return {
      blnFormValid: true,
      objModel: { old_password: "", new_password: "", new_password_repeat: "" },
    };
  },
  async created() {
    this.refresh();
  },
  methods: {
    validate() {},
    async refresh() {
      //
    },
    async saveModel() {
      let blnFormValid = this.$refs.form.validate();
      if (!blnFormValid) {
        return;
      }

      if (this.objModel.new_password != this.objModel.new_password_repeat) {
        this.showSnackbar("Please confirm the new password again.");
        return;
      }
      const strView = this.view;
      const strOldPassword = this.objModel.old_password;
      const strNewPassword = this.objModel.new_password;
      const intArrangerId = this.objArranger.id;
      let objResponse = {};
      try {
        if (strView == "admin") {
          objResponse = await api.resetArrangerPassword({
            strView,
            strNewPassword,
            intArrangerId,
          });
        } else {
          objResponse = await api.resetArrangerPassword({
            strView,
            strOldPassword,
            strNewPassword,
          });
        }

        if (objResponse.blnOK) {
          
          this.objModel.old_password = "";
          this.objModel.new_password = "";
          this.objModel.new_password_repeat = "";
          this.$emit("saved");
        } else {
          this.showSnackbar("Error:" + objResponse.strMessage);
        }
      } catch (e) {
        this.showSnackbar(e.message);
      }
    },
  },
};
</script>

<style></style>
