//import Vue from "vue";
import axios from "axios";
let strKey = "6Lc9a8gZAAAAAPlfzO0EETiVI5t8rBkSmWTseU9a";
let strBaseUrl = "http://localhost:3000";
if (process.env.NODE_ENV == "production") {
  strKey = "6LfmacgZAAAAAO6unh0hiJcXxF03ZIlMEJOZQKf7";
}
strKey = "6LfmacgZAAAAAO6unh0hiJcXxF03ZIlMEJOZQKf7";

strBaseUrl = "https://stagingbrokerageapi.recl.co.in";

const client = axios.create({
  baseURL: strBaseUrl,
  json: true,
});

export default {
  getRecaptchaKey() {
    return strKey;
  },
  getServerUrl() {
    return strBaseUrl;
  },
  async execute(strView = "", method, resource, data) {
    console.log("calling resource" + resource);
    // inject the accessToken for each request
    //let accessToken = await Vue.prototype.$auth.getAccessToken();

    let strKey = "";
    if (strView == "admin") {
      strKey = "objAdmin";
    } else if (strView == "arranger") {
      strKey = "objArranger";
    }
    let objUser = JSON.parse(localStorage.getItem(strKey));
    console.log("objUser", objUser);

    let strToken = "";
    var headers = {};
    if (objUser) {
      strToken = objUser.strToken;
      if (strToken) {
        //console.log("it is set");
      } else {
        //console.log(objUser);
        //console.log(objUser.strToken);
        //console.log("str token ins notset");
      }
      //console.log("setting headrs");

      headers = {
        Authorization: `Bearer ${strToken}`,
      };
    }

    console.log("headers", headers);
    console.log("calling");

    try {
      let objResponse = await client({
        method,
        url: resource,
        data,
        headers,
      });
      return objResponse.data;
    } catch (e) {
      console.log(e.message);
      //we will set the localstorge key
      //to null so that the router
      //can take back to the
      //login page
      if (strKey != "") {
        localStorage.setItem(strKey, "");
      }
    }

    //console.log("data", objResponse.data);
  },
  async loginArranger(objModel) {
    return this.execute("", "POST", "/arranger-login", objModel);
  },
  async loginAdmin(objModel) {
    return this.execute("", "POST", "/admin-login", objModel);
  },
  async getArrangerLots() {
    return this.execute("arranger", "GET", "/arranger/lots");
  },
  async getArrangerLotRecords(intLotId) {
    return this.execute("arranger", "GET", `/arranger/lots/${intLotId}`);
  },
  async getArrangerLotRecord(intLotId, intRecordId) {
    return this.execute(
      "arranger",
      "GET",
      `/arranger/lots/${intLotId}/brokerage-records/${intRecordId}`
    );
    /*return this.execute(
      "GET",
      `/arranger/lots/${intLotId}/records/${intRecordId}`
    );*/
  },
  async getAdminLots() {
    return this.execute("admin", "GET", "/admin/lots");
  },
  async getAdminLotRecords(intLotId) {
    return this.execute("admin", "GET", `/admin/lots/${intLotId}`);
  },
  async getAdminLotRecord(intLotId, intRecordId) {
    return this.execute(
      "admin",
      "GET",
      `/admin/brokerage-records/${intRecordId}`
    );
  },
  async getArranger(intArrangerId, blnIncludeLots = false) {
    return this.execute(
      "admin",
      "GET",
      `/admin/arrangers/${intArrangerId}?includeLots=${blnIncludeLots}`
    );
  },
  logoutArranger() {
    console.log("logging out objArranger");
    localStorage.removeItem("objArranger");
  },
  logoutAdmin() {
    console.log("logging out objArranger");
    localStorage.removeItem("objAdmin");
  },

  async getArrangerHome() {
    return await this.execute("arranger", "GET", "/arranger/home");
  },
  async getAdminHome() {
    return await this.execute("admin", "GET", "/admin/home");
  },
  async getArrangers() {
    return await this.execute("admin", "GET", "/admin/arrangers");
  },
  async uploadFile(file) {
    var objFormData = new FormData();
    //objFormData.append("objModel", JSON.stringify(this.objModel));
    objFormData.append("documents", file);

    var strUrl = "/admin/documents/upload";

    let obj = await this.execute("admin", "POST", strUrl, objFormData);

    return obj;
  },
  async createLot({ objSheetFile, objModel }) {
    console.log("the file", objSheetFile);
    console.log("objModel", objModel);

    var objFormData = new FormData();
    //objFormData.append("objModel", JSON.stringify(this.objModel));
    objFormData.set("documents", objSheetFile);
    objFormData.set("objModel", JSON.stringify(objModel));

    //https://stackoverflow.com/questions/20963273/spa-best-practices-for-authentication-and-session-management
    var strUrl = "/admin/lots/";

    let obj = await this.execute("admin", "POST", strUrl, objFormData);

    return obj;
  },
  async createArranger(objModel) {
    objModel.id = objModel.name;
  },
  async updateArranger(objModel, strView) {
    if (strView == "admin") {
      let strUrl = "/admin/arrangers/" + objModel.id;
      let obj = await this.execute(strView, "PUT", strUrl, {
        objModel,
      });
      return obj;
    } else if (strView == "arranger") {
      let strUrl = "/arranger/";
      let obj = await this.execute(strView, "PUT", strUrl, {
        objModel,
      });
      return obj;
    }
  },
  async updateSummaryRecord({ objFile, objModel }, strView) {
    console.log("the file", objFile);
    console.log("objModel", objModel);

    if (strView == "admin") {
      let strUrl = `/admin/arrangers/${objModel.arranger_id}/summary-records/${objModel.id}`;
      let obj = await this.execute(strView, "PUT", strUrl, {
        objModel,
      });
      return obj;
    } else if (strView == "arranger") {
      var objFormData = new FormData();
      objFormData.set("documents", objFile);
      objFormData.set("objModel", JSON.stringify(objModel));
      let strUrl = "/arranger/summary-records/" + objModel.id;
      let obj = await this.execute(strView, "PUT", strUrl, objFormData);
      return obj;
    }
  },
  async getSummaryRecord(intSummaryRecordId, strView) {
    if (strView == "admin") {
      let strUrl = `/admin/summary-records/${intSummaryRecordId}`;
      let obj = await this.execute(strView, "GET", strUrl, {});
      return obj;
    } else if (strView == "arranger") {
      let strUrl = "/arranger/summary-records/" + intSummaryRecordId;
      let obj = await this.execute(strView, "GET", strUrl, {});
      return obj;
    }
  },
  async getAdminLotSummaryRecords(intLotId) {
    return await this.execute(
      "admin",
      "GET",
      `/admin/lots/${intLotId}/summary-records`
    );
  },
  async getArrangerLotSummaryRecords(intLotId) {
    return await this.execute(
      "admin",
      "GET",
      `/arranger/lots/${intLotId}/summary-records`
    );
  },
  async getAdminLotBrokerageRecords(intLotId) {
    return await this.execute(
      "admin",
      "GET",
      `/admin/lots/${intLotId}/brokerage-records`
    );
  },
  async getArrangerLotBrokerageRecords(intLotId) {
    return await this.execute(
      "arranger",
      "GET",
      `/arranger/lots/${intLotId}/brokerage-records`
    );
  },
  async getAdminBills() {
    return await this.execute("admin", "GET", `/admin/bills`);
  },
  async getAdminBillWisePayments() {
    return await this.execute("admin", "GET", `/admin/bills`);
  },
  async forgotPassword(objModel, strView) {
    if (strView == "admin") {
      let strUrl = `/admin/forgot-password/`;
      let obj = await this.execute(strView, "POST", strUrl, {
        objModel,
      });
      return obj;
    } else if (strView == "arranger") {
      let strUrl = "/arranger/forgot-password/";
      let obj = await this.execute(strView, "POST", strUrl, {
        objModel,
      });
      return obj;
    }
  },
  async updatePassword(objModel, strView) {
    if (strView == "admin") {
      let strUrl = `/admin/update-password/`;
      let obj = await this.execute(strView, "POST", strUrl, {
        objModel,
      });
      return obj;
    } else if (strView == "arranger") {
      let strUrl = "/arranger/update-password/";
      let obj = await this.execute(strView, "POST", strUrl, {
        objModel,
      });
      return obj;
    }
  },
  async getAdminAdvancePayments() {
    let strUrl = `/admin/advance-payments/`;
    let obj = await this.execute("admin", "GET", strUrl);
    return obj;
  },
  async getAdminArrangerLots(intArrangerId) {
    return this.execute(
      "admin",
      "GET",
      `/admin/arrangers/${intArrangerId}/lots`
    );
  },
  async createAdvancePayment({ objModel }) {
    let strUrl = "/admin/advance-payments/";
    let obj = await this.execute("admin", "POST", strUrl, {
      objModel,
    });

    return obj;
  },
  async createAdvancePayment2({ objModel, arrSummaryRecordIds }) {
    let strUrl = "/admin/advance-payments2/";
    let obj = await this.execute("admin", "POST", strUrl, {
      objModel,
      arrSummaryRecordIds,
    });

    return obj;
  },
  async resetArrangerPassword({
    strView,
    strOldPassword,
    strNewPassword,
    intArrangerId = 0,
  }) {
    let strUrl = "";

    if (strView == "admin") {
      strUrl = `/admin/arrangers/${intArrangerId}/reset-password/`;
    } else if (strView == "arranger") {
      strUrl = "/arranger/reset-password/";
    }

    let objResponse = await this.execute(strView, "POST", strUrl, {
      strOldPassword,
      strNewPassword,
    });

    return objResponse;
  },
  async deleteLot(intLotId) {
    let strUrl = "/admin/lots/" + intLotId;
    let obj = await this.execute("admin", "DELETE", strUrl);
    if (!obj.blnOK) {
      throw new Error(
        "Unable to delete. There are some records that depend on this lot.Please contact the admin"
      );
    }
    return obj;
  },
};
