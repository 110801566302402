import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import AdminArrangers from "../views/admin/AdminArrangers.vue";
import UploadLot from "../views/admin/UploadLot.vue";

import AdminLayout from "../layouts/AdminLayout.vue";
import ArrangerLayout from "../layouts/ArrangerLayout.vue";
import AdminHome from "../views/admin/AdminHome.vue";

import ArrangerHome from "../views/arranger/ArrangerHome.vue";
import ArrangerLogin from "../views/arranger/ArrangerLogin.vue";
import AdminLogin from "../views/admin/AdminLogin.vue";
import AdminArranger from "../views/admin/AdminArranger.vue";

import AdminLots from "../views/admin/AdminLots.vue";
import ArrangerLots from "../views/arranger/ArrangerLots.vue";
import AdminBills from "../views/admin/AdminBills.vue";
import AdminAdvancePayments from "../views/admin/AdminAdvancePayments.vue";

import LotRecords from "../views/LotRecords.vue";
import LotRecord from "../views/LotRecord.vue";
//import Arranger from "../views/Arranger.vue";

import LotSummaryRecords from "../views/LotSummaryRecords.vue";
import LotBrokerageRecords from "../views/admin/LotBrokerageRecords.vue";
//import ForgotPasswordForm from "../components/ForgotPasswordForm.vue";
import MakeAdvancePayment from "../views/admin/MakeAdvancePayment.vue";
import MakeAdvancePaymentLotWise from "../views/admin/MakeAdvancePaymentLotWise.vue";

export default store => {
  Vue.use(VueRouter);

  const routes = [
    /*   {
      // not found handler
      path: '*',
      component: NotFoundView
    },*/

    /*{
      path: "/upload-lot",
      component: UploadLot
    },*/
    {
      path: "/arranger-login",
      component: ArrangerLogin
    },
    {
      path: "/admin-login",
      component: AdminLogin
    },
    {
      path: "/admin",
      component: AdminLayout,
      meta: { guard: "admin" },

      children: [
        {
          path: "home",
          component: AdminHome
        },
        {
          path: "arrangers",
          component: AdminArrangers
        },
        {
          path: "arrangers/:id/",
          component: AdminArranger,
          props: route => ({ view: "admin", id: route.params.id })
        },
        {
          path: "upload-lot",
          component: UploadLot
        },
        {
          path: "lots",
          name: "AdminLots",
          component: AdminLots,
          props: { view: "admin" }
        },
        {
          path: "bills",
          component: AdminBills,
          props: { view: "admin" },
          meta: { guard: "admin" }
        },
        {
          path: "advance-payments",
          component: AdminAdvancePayments,
          props: { view: "admin" }
        },
        {
          path: "lots/:lotId/",
          component: LotRecords,
          props: route => ({ view: "admin", lotId: route.params.lotId })
        },
        {
          path: "lots/:lotId/summary-records",
          component: LotSummaryRecords,
          props: route => ({ view: "admin", lotId: route.params.lotId })
        },
        {
          path: "lots/:lotId/brokerage-records",
          component: LotBrokerageRecords,
          props: route => ({ view: "admin", lotId: route.params.lotId })
        },
        {
          path: "lots/:lotId/brokerage-records/:recordId",
          component: LotRecord,
          props: route => ({
            view: "admin",
            lotId: route.params.lotId,
            recordId: route.params.recordId
          })
        },
        {
          path: "lots/:lotId/arranger/:arrangerId/summary-records",
          component: LotSummaryRecords,
          props: route => ({
            view: "admin",
            lotId: route.params.lotId,
            arrangerId: route.params.arrangerId
          })
        },
        {
          path: "make-advance-payment",
          component: MakeAdvancePayment,
          props: route => ({
            view: "admin"
          })
        },
        {
          path: "make-advance-payment-lot-wise",
          component: MakeAdvancePaymentLotWise,
          props: route => ({
            view: "admin"
          })
        },

        {
          path: "*",
          component: AdminHome
        }
      ]
    },

    {
      path: "/arranger",
      component: ArrangerLayout,
      meta: { guard: "arranger" },
      children: [
        {
          path: "home",
          component: ArrangerHome
        },

        {
          path: "lots",
          component: ArrangerLots,
          props: { view: "arranger" }
        },
        {
          path: "lots/:lotId/",
          component: LotRecords,
          props: route => ({ view: "arranger", lotId: route.params.lotId })
        },
        {
          path: "lots/:lotId/brokerage-records",
          component: LotBrokerageRecords,
          props: route => ({
            view: "arranger",
            lotId: route.params.lotId
          })
        },
        {
          path: "lots/:lotId/brokerage-records/:recordId",
          component: LotRecord,
          props: route => ({
            view: "arranger",
            lotId: route.params.lotId,
            recordId: route.params.recordId
          })
        },

        {
          path: "*",
          component: ArrangerHome
        }
      ]
    },
    {
      path: "*",
      component: Home
    }

    /* {
      path: "/",
      component: Home,
      children: [
        {
          path: "/structures/:id",

          component: Structure,
          props: true,
        },
        {
          path: "/structures/:sId/levels/:id",

          component: Level,
          props: true,
        },
        {
          path: "/units",

          component: Units,
          props: true,
        },
        {
          path: "/login",

          component: Login,
          props: true,
        },
        {
          path: "/about",
          name: "about",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("../views/About.vue"),
        },
        {
          path: "/structures",
          name: "structures",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              "../views/Structures.vue"
            ),
        },
      ],
    },*/
  ];

  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });
  /*eslint no-unreachable: 0*/
  /*eslint no-unused-vars: 0*/
  router.beforeEach((to, from, next) => {
    /*console.log("to", to);

    //var lst = to.matched.pop();
    //console.log("lst",lst);
    var arrMatches = to.matched;
    var intLen = arrMatches.length;

    var last = arrMatches[intLen - 1];

    //console.log("lst",lst);

    if (last) {
      console.log("inside", last);
      var strGuard = last.meta.guard;
      console.log("guard", strGuard);
      if (strGuard == "arranger") {
        let item = localStorage.getItem("objArranger");
        console.log("item", item);
        if (!item) {
          return next("/arranger-login");
        }
      }
      if (strGuard == "admin") {
        let item = localStorage.getItem("objAdmin");
        console.log("item", item);
        if (!item) {
          return next("/admin-login");
        }
      }
    }
    console.log("going next", last);
    next();
    */
    //next();
    let objRecord = to.matched.find(record => record.meta.guard);
    if (objRecord) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      let strGuard = objRecord.meta.guard;
      console.log("guard", strGuard);
      let strKey;
      if (strGuard == "arranger") {
        strKey = "objArranger";
      } else if (strGuard == "admin") {
        strKey = "objAdmin";
      }
      let objUser = null;
      try {
        let strValue = localStorage.getItem(strKey);

        objUser = JSON.parse(strValue);
      } catch (e) {
        console.log("CAUGHT HERE" + e);
      }

      if (!objUser) {
        let strPath = "/";
        if (strGuard == "arranger") {
          strPath = "/arranger-login";
        } else if (strGuard == "admin") {
          strPath = "/admin-login";
        }
        next({
          path: strPath,
          query: { redirect: to.fullPath }
        });
      } else {
        next();
      }
    } else {
      next(); // make sure to always call next()!
    }
  });
  /*
  router.beforeEach((to, from, next) => {
    //store.state.blnloading = true;
    //store.state.name = "loading";
    //store.commit("blnLoading", true);
    //console.log(to.path);
    if (to.path == "/login") {
      return next();
    }
    if (store.state.blnAuthenticated == false) {
      // next();
      next("/login");
    } else {
      next();
    }
  });*/

  /* router.afterEach((to, from) => {
   // store.state.blnloading = false;
    //store.commit("blnLoading", false);
    store.state.name = "loadded";
  });*/

  return router;
};
