<template>
  <v-card width="100%" min-height="400">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>Make Advance Payment</h4>
    </v-card-title>

    <v-card-text>
      <label class="black--text mb5px dib100"
        ><strong>Select LOT</strong></label
      >
      <div>
        <v-spacer>
          <v-autocomplete
            :items="arrLots"
            item-text="lot_number"
            :return-object="blnReturnObject"
            v-model="objLot"
            @change="handleLotChange"
            outlined
            placeholder="Please choose"
          ></v-autocomplete>
        </v-spacer>
      </div>
      <template v-if="objLot.id">
        <v-divider></v-divider>
        <v-card-title class="black--text pl0px pr0px">
          <h4>Summary Records for Lot {{ objLot.lot_number }}</h4>
          <v-spacer></v-spacer>
          <div class="tableSearch mr10px">
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              rounded
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="my-2" @click="download">
            <v-btn depressed color="success" dark class="ttn">
              <v-icon class="mr5px">mdi-file-excel</v-icon> Download
            </v-btn>
          </div>
        </v-card-title>

        <v-data-table
          show-select
          item-key="id"
          v-model="arrSelSummaryRecords"
          :headers="arrHeaders"
          :items="arrItems"
          :search="search"
        >
          <template v-slot:item.lot_number="{ item }">
            {{ item.lot_number }}
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:item.TotalBillAmount="{ item }">
            {{ item._total_bill_amount }}
          </template>
          <template v-slot:item.BillNumber="{ item }">
            {{ item._bill_number }}
          </template>
        </v-data-table>

        <v-btn
          depressed
          x-large
          color="success"
          @click="showPaymentForm"
          :disabled="isPayButtonDisabled()"
          ><v-icon class="mr5px">mdi-currency-inr</v-icon> Pay</v-btn>
      </template>
      <v-dialog v-model="blnShowForm" persistent max-width="600px">
        <v-btn @click="closeForm">Close </v-btn>
        <AdvancePaymentForm2
          v-if="blnShowForm"
          @saved="handleAdvancePaymentSave"
          :arrSummaryRecords="arrSelSummaryRecords"
        ></AdvancePaymentForm2>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import AdvancePaymentForm2 from "@/components/AdvancePaymentForm2.vue";
export default {
  components: { AdvancePaymentForm2 },
  computed: {
    arr() {
      //return this.$refs["tbl"].value;
      return [];
    },
  },
  data() {
    return {
      search: "",
      blnClearable: true,
      blnReturnObject: true,
      blnShowForm: false,
      objLot: {},
      arrLots: [],
      loading: true,
      arrItems: [],
      model: {},
      dialog: false,
      arrSelSummaryRecords: [],

      arrBreadcrumbs: (this.arrBreadcrumbs = [
        {
          text: "Home",
          disabled: false,
          to: `/admin/home`,
        },
        {
          text: "Make Advance Payment",
          disabled: true,
        },
      ]),

      arrHeaders: [
        /*{
          text: 'Select',
          align: 'left',
          sortable: true,
          value: 'select'
        },*/
        {
          text: "Arranger",
          align: "left",
          sortable: true,
          value: "arranger_name",
        },

        /*{
          text: 'BondsAlloted',
          align: 'left',
          sortable: true,
          value: 'bonds_alloted'
        },
        {
          text: 'BondsAmount',
          align: 'left',
          sortable: true,
          value: 'amount_on_application_fmt'
        },*/
        {
          text: "GrossBrokerage",
          align: "left",
          sortable: true,
          value: "gross_brokerage_fmt",
        },
        {
          text: "Incentive",
          align: "left",
          sortable: true,
          value: "incentive_below_250cr_fmt",
        },
        {
          text: "TotalCommission",
          align: "left",
          sortable: true,
          value: "total_commission_fmt",
        },
        {
          text: "GST",
          align: "left",
          sortable: true,
          value: "gst_fmt",
        },
        {
          text: "NetBrokerage",
          align: "left",
          sortable: true,
          value: "net_brokerage_fmt",
        },

        {
          text: "TotalBillAmount",
          align: "left",
          sortable: true,
          value: "_total_bill_amount_fmt",
        },
        {
          text: "BillStatus",
          align: "left",
          sortable: true,
          value: "_bill_status_fmt",
        },
        {
          text: "BillNumber",
          align: "left",
          sortable: true,
          value: "_bill_number",
        },
        {
          text: "AdvancePaid",
          align: "left",
          sortable: true,
          value: "__advance_paid",
        },
        {
          text: "AdvanceAmount",
          align: "left",
          sortable: true,
          value: "__advance_amount_fmt",
        },
      ],
    };
  },
  async created() {
    this.refresh();
  },
  methods: {
    download() {
      const strFileName = "Summary Records for Lot#" + this.objLot.lot_number;
      let arrXLSHeaders = this._getXLSHeaders(this.arrHeaders);

      this.downloadXLS(this.arrItems, arrXLSHeaders, strFileName);
    },
    async handleLotChange() {
      if (!this.objLot) {
        return;
      }
      this.arrSelSummaryRecords = [];
      const intLotId = this.objLot.id;
      this.loading = true;
      try {
        const obj = await api.getAdminLotSummaryRecords(intLotId);
        let arrSummaryRecords = obj.summary_records;

        arrSummaryRecords = arrSummaryRecords.map((objItem) => {
          objItem = this.formatSummaryRecord(objItem);
          console.log(objItem);
          try {
            objItem.arranger_name = objItem.arranger.name;
          } catch (e) {
            objItem.arranger_name = "n/a";
          }

          return objItem;
        });
        this.arrItems = arrSummaryRecords;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    isPayButtonDisabled() {
      if (this.arrSelSummaryRecords.length == 0) {
        return true;
      }
      return false;
    },
    isBillUploaded() {},
    async handleArrangerChange() {
      await this.getSelectedArrangerLots();
    },
    handleAdvancePaymentSave() {
      this.showSnackbar("Advance Payment Record Created!");
      this.blnShowForm = false;
      this.handleLotChange();
      //this.$router.replace('/admin/advance-payments')
    },
    showPaymentForm() {
      this.blnShowForm = true;

    },
    closeForm() {
      this.blnShowForm = false;
    },
    get(obj, strPropName) {
      if (strPropName in obj) {
        //console.log('getting', strPropName)
        //console.log('returning', obj[strPropName])
        return obj[strPropName];
      }

      return "n/a";
    },
    async refresh() {
      this.loading = true;
      try {
        this.arrLots = await api.getAdminLots();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
