import Vue from "vue";

import Vuex from "vuex";
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";

//import validator from "validator";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import BareLayout from "./layouts/DefaultLayout.vue";

Vue.component("DefaultLayout", DefaultLayout);
Vue.component("BareLayout", BareLayout);

Vue.config.productionTip = false;

Vue.use(Vuex);

import store from "./store";

//set up the vuex with
try {
  const objAdmin = JSON.parse(localStorage.getItem("objAdmin"));
  const objArranger = JSON.parse(localStorage.getItem("objArranger"));
  if (objAdmin) {
    store.commit("setAdmin", objAdmin);
  }
  if (objArranger) {
    store.commit("setArranger", objArranger);
  }
} catch (e) {console.log(e)}

import makeRouter from "./router";
let router = makeRouter(store);
import Common from "@/common.js";
import myMixin from "@/mixins/index.js";

Vue.mixin({
  methods: {
    formatDate(strDate) {
      return Common.formatDate(strDate);
    }
  }
});

Vue.mixin(myMixin);

new Vue({
  router,
  vuetify,

  store: store,
  render: h => h(App)
}).$mount("#app");
