<template>
  <v-card width="100%" min-height="600">
    <v-overlay :value="blnSaving">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <template>
      <div></div>
    </template>
    <v-card-title>
      Make Advance Payment for {{ getArrangerNames() }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="blnFormValid">
        <v-text-field
          dense
          label="Amount"
          v-model="objModel.amount"
          :rules="[requiredRule,positiveNumberRule]"
          type="number"
          :min="1"

        >
        </v-text-field>
        <v-text-field
          required
          dense
          label="Payment Ref"
          v-model="objModel.payment_ref"
          :rules="requiredRules"
        >
        </v-text-field>
        <v-menu
          ref="blnPickDate"
          v-model="blnPickDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="objModel.payment_date"
              label="Date"
              hint="YYYY-MM-DD format"
              persistent-hint
              prepend-icon="event"
              readonly=""
              v-on="on"
              :rules="[requiredRule]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="objModel.payment_date"
            no-title
            @input="blnPickDate = false"
          ></v-date-picker>
        </v-menu>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveModel">Save</v-btn>
      </v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "AdvancePaymentForm2",
  props: {
    view: String,
    arrSummaryRecords: Array,
  },
  data() {
    return {
      blnPickDate: false,
      objModel: {},
      blnFormValid: false,
      blnSaving: false,
    };
  },
  async mounted() {
    this.refresh();
  },
  async created() {},
  methods: {
    getArrangerNames() {
      const arr = this.arrSummaryRecords.map((obj) => {
        try {
          return obj.arranger.name;
        } catch (e) {
          return "n/a";
        }
      });
      return arr.join(", ");
    },
    validate() {
      if (this.$refs.form.validate()) {
        return true;
      }
      return false;
    },
    async refresh() {},
    async saveModel() {
      try {
        if (!this.$refs.form.validate()) {
          this.showSnackbar("Form is not valid");
          return;
        }
        this.blnSaving = true;

        const objModel = this.objModel;
        const arrSummaryRecordIds = this.arrSummaryRecords.map((obj) => obj.id);

        let objResponse = await api.createAdvancePayment2({
          objModel,
          arrSummaryRecordIds,
        });

        if (!objResponse.blnOK) {
          throw new Error(objResponse.strMessage);
        }
        this.$emit("saved");
      } catch (e) {
        console.log(e);
        this.showSnackbar(e.message);
      } finally {
        console.log("done");
        this.blnSaving = false;
      }
    },
  },
};
</script>
