<template>
  <v-card width="100%" min-height="600">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>Arrangers</h4>
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="my-2" @click="download">
        <v-btn depressed color="success" dark class="ttn">
          <v-icon class="mr5px">mdi-file-excel</v-icon> Download
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table :headers="arrHeaders" :items="arrItems" :search="search">
      <template v-slot:item.name="{ item }">
        <router-link :to="'/admin/arrangers/' + item.id">{{
          item.name
        }}</router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from '@/api'

export default {
  name: 'Arrangers',

  data () {
    return {
      search: '',
      arrHeaders: [
        {
          text: 'S.No',
          align: 'left',
          sortable: true,
          value: 'index'
        },
        {
          text: 'ARNCode',
          align: 'left',
          sortable: true,
          value: 'code'
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Contacts',
          align: 'left',
          sortable: true,
          value: 'contacts'
        },
        {
          text: 'Mobile',
          align: 'left',
          sortable: true,
          value: 'mobile_numbers'
        },
        {
          text: 'Emails',
          align: 'left',
          sortable: true,
          value: 'emails'
        }
      ],
      loading: true,
      arrItems: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          to: '/admin/home'
        },
        {
          text: 'Arrangers',
          disabled: true
        }
      ]
    }
  },
  async created () {
    this.refresh()
  },
  methods: {
    download () {
      const strFileName = 'AllArrangers'
      this.downloadXLS(this.arrItems, this.arrHeaders, strFileName)
    },
    getUnitAddress (objItem) {
      console.log(objItem)
      return objItem.UnitAddress.OneLine
    },
    getViewLink (objItem) {
      return objItem.UnitAddress.OneLine
    },
    async refresh () {
           this.loading = true
      try {
        this.arrItems = await api.getArrangers()
        this.arrItems = this.arrItems.map((obj, index) => {
          obj.index = index + 1
          return obj
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
