<template>
  <v-card width="100%" min-height="500">
    <v-card-title>
      Home
    </v-card-title>
    <Arranger view="arranger" :objModel="objModel" />
  </v-card>
</template>

<script>
// @ is an alias to /src
import api from "@/api";
import Arranger from "@/components/Arranger.vue";
export default {
  name: "home",
  data: () => ({
    menu: false,
    loading: false,
    objModel: {}
  }),

  async created() {
    this.refresh();
  },
  components: { Arranger },
  methods: {
    async refresh() {
      this.loading = true;
      try {
        let objResponse = await api.getArrangerHome();
        this.objModel = objResponse.objModel;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
