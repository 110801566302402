<template>
  <v-container class="fill-height justify-center" fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-card max-width="450" class="mx-auto">
          <v-card-text class="p40px">
            <div class="mb25px"><img src="../assets/logo.png" alt="" /></div>
            <div class="dflex justify-between">
              <v-btn depressed large color="primary" to="/arranger-login" class="w48">Arranger Login</v-btn>
              <v-btn depressed large color="primary" to="/admin-login" class="w48">Admin Login</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      objModel: { email: "", password: "" },
      showPassword: false
    };
  },
  methods: {}
};
</script>
