<template>
  <v-container class="fill-height justify-center" fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-card max-width="450" class="mx-auto">
          <v-card-text class="p40px">
            <div class="mb25px"><img src="../../assets/logo.png" alt="" /></div>
            <v-snackbar v-model="blnSnackbar">
              {{ strSnackbarText }}
            </v-snackbar>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-text-field
                v-model="objModel.username"
                label="Username"
                hint="000xy, where xy is your ARN Code"
                placeholder="000xy, where xy is your ARN Code"
                :persistent-hint="true"
                outlined
                :rules="usernameRules"
                required
                dense
              ></v-text-field>
              <v-text-field
                v-model="objModel.password"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                outlined
                required
                :rules="requiredRules"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <vue-recaptcha
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="strRecaptchaKey"
              ></vue-recaptcha>
              <v-btn
                color="primary"
                x-large
                depressed
                @click="login"
                class="w100"
                :loading="blnLoading"
                >Login <v-icon class="ml-3">mdi-arrow-right</v-icon></v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api";
import myMixin from "@/mixins/index.js";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "LoginPage",
  mixins: [myMixin],
  data() {
    return {
      blnLoading: false,

      valid: true,
      objModel: { username: "", password: "" },
      showPassword: false,
      blnVerified: false,
      strRecaptchaKey: api.getRecaptchaKey(),
    };
  },
  components: { VueRecaptcha },

  methods: {
    onSubmit: function() {
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function(response) {
      //alert("Verify: " + response);
      this.blnVerified = true;
    },
    onExpired: function() {
      console.log("Expired");
    },
    async login() {
      let isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.blnLoading = true;

      let objResponse = await api.loginArranger(this.objModel);
      if (objResponse.blnOK) {
        console.log("response.ok");
        let objUser = objResponse.objUser;
        localStorage.setItem("objArranger", JSON.stringify(objUser));
        this.$store.commit("setArranger", objUser);
        this.$router.replace("/arranger/home");
      } else {
        this.showSnackbar("Incorrect Username/Password");
        console.log("could not login");
      }

      this.blnLoading = false;
    },
  },
};
</script>
