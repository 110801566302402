<template>
  <v-card width="100%" min-height="600">
    <v-card-title>
      <h4>Arranger {{ objModel.name }}</h4>
    </v-card-title>
    <v-divider></v-divider>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <v-card-text class="mt15px pb0px">
      <v-form ref="form" v-model="blnFormValid">
        <v-row>
          <v-col cols="12" sm="6">
            <v-textarea
              :rules="[specialCharRule]"
              height="112px"
              label="Address"
              v-model="objModel.address"
              outlined
            >
            </v-textarea>
            <v-text-field
              :rules="[specialCharRule]"
              label="Pincode"
              v-model="objModel.pincode"
              outlined
            >
            </v-text-field>
            <v-textarea
              :rules="[specialCharRule]"
              height="112px"
              label="Phone Numbers"
              v-model="objModel.phone_nums"
              outlined
            >
            </v-textarea>
            <v-text-field
              :rules="[specialCharRule]"
              label="Station"
              v-model="objModel.station"
              outlined
            >
            </v-text-field>
            <v-text-field
              label="Tax Status"
              :rules="[specialCharRule]"
              v-model="objModel.tax_status"
              outlined
            >
            </v-text-field>
            <v-text-field
              :rules="[specialCharRule]"
              label="PAN"
              v-model="objModel.pan_num"
              outlined
            >
            </v-text-field>
            <v-text-field
              :rules="[specialCharRule]"
              label="Tax Rate"
              v-model="objModel.tax_rate"
              outlined
            >
            </v-text-field>
            <v-text-field
              :rules="[specialCharRule]"
              clear-icon=""
              label="Exempted"
              v-model="objModel.exempted"
              outlined
            >
            </v-text-field>
            <v-text-field
              :rules="[specialCharRule]"
              label="Limit"
              v-model="objModel._limit"
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :rules="[specialCharRule]"
              label="Service Tax"
              v-model="objModel.service_tax"
              outlined
            >
            </v-text-field>
            <v-text-field
              label="Bank Name"
              :rules="[specialCharRule]"
              v-model="objModel.bank_name"
              outlined
            >
            </v-text-field>
            <v-text-field
              label="Bank Account Num"
              :rules="[specialCharRule]"
              v-model="objModel.bank_account_num"
              outlined
            >
            </v-text-field>
            <v-text-field
              label="IFSC Code"
              :rules="[specialCharRule]"
              v-model="objModel.ifsc_code"
              outlined
            >
            </v-text-field>
            <v-text-field
              label="MICR Code"
              :rules="[specialCharRule]"
              v-model="objModel.micr_code"
              outlined
            >
            </v-text-field>
            <v-text-field
              label="Bank Branch"
              :rules="[specialCharRule]"
              v-model="objModel.bank_branch"
              outlined
            >
            </v-text-field>
            <v-textarea
              height="112px"
              label="Contacts"
              :rules="[specialCharRule]"
              v-model="objModel.contacts"
              outlined
            >
            </v-textarea>
            <v-textarea
              height="112px"
              label="Mobile Numbers"
              :rules="[specialCharRule]"
              v-model="objModel.mobile_numbers"
              outlined
            >
            </v-textarea>
            <v-textarea
              height="112px"
              label="Emails"
              
              v-model="objModel.emails"
              outlined
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <div class="pl8px pr8px w100 ac">
        <v-btn
          depressed
          large
          color="success"
          class="w30 w50-md w100-sm"
          @click="saveModel"
          >Save</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "ArrangerForm",
  props: {
    objModel: Object,
    view: String,
    id: Number,
  },
  data() {
    return { blnFormValid: true };
  },
  async created() {
    this.refresh();
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        return true;
      }
      return false;
    },
    async refresh() {
      //
    },
    async saveModel() {
      if (!this.validate()) {
        this.showSnackbar("Form must be valid");
        return;
      }

      try {
        if (this.objModel.id) {
          await api.updateArranger(this.objModel, this.view);
        } else {
          await api.createArranger(this.objModel, this.view);
        }
        this.$emit("saved");
      } catch (e) {
        this.showSnackbar(e.message);
      } finally {
        console.log("done");
      }
    },
  },
};
</script>
