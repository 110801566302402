<template>
  <v-card width="100%" min-height="500">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="deep-purple accent-4"
    ></v-progress-linear>

    <div>
      <v-breadcrumbs :items="arrBreadcrumbs">
        <template v-slot:item="props">
          <v-breadcrumbs-item
            :to="props.item.to"
            :class="[props.item.disabled]"
            exact
          >
            {{ props.item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <Arranger view="admin" :objModel="objNewModel"> </Arranger>
  </v-card>
</template>

<script>
// @ is an alias to /src
import api from "@/api";
import Arranger from "@/components/Arranger.vue";

export default {
  name: "AdminArranger",
  components: {
    Arranger
  },
  props: ["id", "view"],
  data: () => ({
    menu: false,
    loading: false,
    objModel: {},
    objNewModel: {},
    arrBreadcrumbs: []
  }),
  async created() {
    await this.refresh();
    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Arrangers",
        disabled: false,
        to: `/${this.view}/arrangers`
      }
    ];
    this.arrBreadcrumbs.push({
      text: "Arranger:" + this.objModel.name,
      disabled: false
    });
  },

  methods: {
    async refresh() {
           this.loading = true;
      try {
        const intArrangerId = this.id;
        let objRecord = await api.getArranger(intArrangerId, false);
        this.objModel = objRecord.objModel;
        this.arrItems = objRecord.arrLots;
        this.objNewModel = Object.assign({}, this.objModel);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
