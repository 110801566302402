<template>
  <v-card width="100%" min-height="600">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <v-snackbar v-model="blnSnackbar">{{ strSnackbarText }}</v-snackbar>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >{{ props.item.text.toUpperCase() }}</v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>LOTS</h4>
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field v-model="search" label="Search" single-line rounded dense hide-details></v-text-field>
      </div>
      <div class="my-2" @click="download">
        <v-btn depressed color="success" dark class="ttn">
          <v-icon class="mr5px">mdi-file-excel</v-icon>Download
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table :headers="arrHeaders" :items="arrItems" :search="search">
      <template v-slot:item.lot_number="{ item }">{{ item.lot_number }}</template>

      <template v-slot:item.brokerwise_xxx="{ item }">
        <router-link :to="'lots/' + item.id + '/summary-records'">Brokerwise</router-link>
      </template>
      <template v-slot:item.applicationwise_xxx="{ item }">
        <router-link :to="'lots/' + item.id + '/brokerage-records'">ApplicationWise</router-link>
      </template>

      <template v-slot:item.delete="{ item }">
        <v-btn small @click="deleteLot(item.id)">Delete</v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "Arrangers",
  props: ["view"],

  data() {
    return {
      search: "",

      loading: true,
      arrItems: [],
      arrItems2: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: [],
      arrHeaders: [
        {
          text: "LotNumber",
          align: "left",
          sortable: true,
          value: "lot_number"
        },
        {
          text: "Alloted",
          align: "left",
          sortable: true,
          value: "bonds_alloted"
        },
        {
          text: "BondsAmount",
          align: "left",
          sortable: true,
          value: "amount_on_application_fmt"
        },
        {
          text: "GrossBrokerage",
          align: "left",
          sortable: true,
          value: "gross_brokerage_fmt"
        },
        {
          text: "Incentive",
          align: "left",
          sortable: true,
          value: "incentive_below_250cr_fmt"
        },
        {
          text: "GST",
          align: "left",
          sortable: true,
          value: "gst_fmt"
        },
        {
          text: "Total",
          align: "left",
          sortable: true,
          value: "total_commission_fmt"
        },
        {
          text: "ShareDate",
          align: "left",
          sortable: true,
          value: "createdAt_fmt"
        },
        {
          text: "BrokerWiseDetails",
          align: "left",
          sortable: true,
          value: "brokerwise_xxx"
        },
        {
          text: "AppWiseDetails",
          align: "left",
          sortable: true,
          value: "applicationwise_xxx"
        },
        {
          text: "Delete",
          align: "center",
          sortable: false,
          value: "delete"
        }
      ]
    };
  },
  async created() {
    this.refresh();

    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Lots",
        disabled: true
      }
    ];
  },
  methods: {
    download() {
      const strFileName = "AllLots";
      let arrXLSHeaders = this._getXLSHeaders(this.arrHeaders);

      this.downloadXLS(this.arrItems, arrXLSHeaders, strFileName);
    },
    async deleteLot(intLotId) {
      window.confirm("Are you sure you want to delete this lot?");
      this.blnSaving = true;
      try {
        await api.deleteLot(intLotId);
        this.refresh();
      } catch (e) {
        console.log(e);
        this.showSnackbar(e.message);
      } finally {
        console.log("done");
        this.blnSaving = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        if (this.view == "admin") {
          this.arrItems = await api.getAdminLots();
        } else if (this.view == "arranger") {
          this.arrItems = await api.getArrangerLots();
        }
        //this.arrItems2 = JSON.parse(JSON.stringify(this.arrItems));

        this.arrItems = this.arrItems.map(obj => {
          obj = this.formatLot(obj);
          return obj;
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
