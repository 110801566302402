<template>
  <v-card width="100%">
    <v-overlay :value="blnSaving">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <template>
      <div></div>
    </template>
    <v-card-title>
      <h4>Bill</h4>
      <!---{{objLotSummaryRecord}}-->
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="mt40px">
      <v-form ref="form" v-model="blnFormValid">
        <v-text-field
          label="Total Bill Amount"
          v-model="objModel._total_bill_amount"
          :rules="billRules"
          type="number"
          :min="1"
          outlined
        >
        </v-text-field>
        <v-text-field
          required
          label="Bill Number"
          v-model="objModel._bill_number"
          :rules="requiredRules"
          outlined
          
        >
        </v-text-field>

        <v-file-input
          :rules="fileRules"
          label="Upload Bill"
          v-model="objFile"
          accept=".pdf,.png,.jpeg,.jpg"
          prepend-icon=""
          append-icon="mdi-attachment"
          outlined
        >
        </v-file-input>
        <v-checkbox
          :rules="requiredRules"
          value="accept"
          v-model="objModel.confirmation"
          :label="
            `I/we confirm that Invoice No is correct and the same will be used in filing of GST Return.`
          "
        ></v-checkbox>
      </v-form>
    </v-card-text>
      <v-card-actions>
        <v-btn depressed large color="success" @click="saveModel" class="w100">Save</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "BillForm",
  props: {
    view: String,
    objLotSummaryRecord: Object
  },
  data() {
    return {
      objModel: {},
      blnFormValid: false,
      blnSaving: false,
      fileRules: [
        // value => {
        //   try {
        //     if (this.objFile.name) {
        //       return true;
        //     } else {
        //       return "Required.";
        //     }
        //   } catch (e) {
        //     return "Required.";
        //   }
        // }
      ],
      billRules: [
        value => {
          let objSummaryRecord = this.objLotSummaryRecord
          if (!objSummaryRecord) {
            this.showSnackbar("Summary Record not found");
          }

          let intNetBrokerage = objSummaryRecord.net_brokerage;
          let strMsg = `Amount must be equal to ${intNetBrokerage}`;

          return value == intNetBrokerage ? true : strMsg;
        }
      ]
    };
  },
  async mounted() {
    console.log("mounted" + Date.now());
    this.refresh();
  },
  async created() {
    let objSummaryRecord = this.objLotSummaryRecord;
    if (!objSummaryRecord) {
      this.showSnackbar("Summary Record not found");
    }

    let intNetBrokerage = objSummaryRecord.net_brokerage;
    this.objModel._total_bill_amount = intNetBrokerage;
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        return true;
      }
      return false;
    },
    async refresh() {},
    async saveModel() {
      try {
        if (!this.$refs.form.validate()) {
          this.showSnackbar("Form is not valid");
          return;
        }
        this.blnSaving = true;
        const objFile = this.objFile;
        const objModel = this.objModel;

        this.objModel.id = this.objLotSummaryRecord.id;

        let objResponse = await api.updateSummaryRecord(
          {
            objFile,
            objModel
          },
          "arranger"
        );

        if (!objResponse.blnOK) {
          throw new Error(objResponse.strMessage);
        }
        this.$emit("saved");
      } catch (e) {
        console.log(e);
        this.showSnackbar(e.message);
      } finally {
        console.log("done");
        this.blnSaving = false;
      }
    }
  }
};
</script>
