<template>
  <div>
    <v-content>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="primary"
        dark
        class="topHeader"
      >
        <div class="logoTop">
          <img
            src="../assets/logo.png"
            alt=""
            style="height:68px; width:auto;"
          />
        </div>
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>

        <v-list
          dense
          tile
          nav
          dark
          color="primary"
          class="topMenuList hidden-md-and-down2"
        >
          <v-list-item link router-link to="/arranger/home">
            <v-list-item-action>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>Home</v-list-item-content>
          </v-list-item>

          <v-list-item link router-link to="/arranger/lots">
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>Lots</v-list-item-content>
          </v-list-item>
        </v-list>

        <v-spacer></v-spacer>

        <v-menu :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" s tile depressed v-on="on" class="accountBtn"
              ><v-icon class="mr5px">mdi-account</v-icon> Arranger
            </v-btn>
          </template>

          <v-card width="200" class="text-center">
            <v-card-text class="text-center">
              <div class="hdrUsername mt10px mb10px">
                Hello
                <span class="blue--text">
                  {{ $store.state.objArranger.name }}
                </span>
              </div>
              <v-btn
                @click="logout"
                color="error"
                depressed
                rounded
                small
                class="ttn"
                >Logout</v-btn
              >
            </v-card-text>
          </v-card>
        </v-menu>
      </v-app-bar>

      <div class="dib100 p16px mainContent">
        <router-view></router-view>
      </div>

      <!-- <v-footer color="white" app>
        <div class="dib100 fs14px">
          <span class="gray--text fl"
            >Copyrights &copy; 2018-2019
            <span class="primary--text">REC</span> All rights
            reserved</span
          >
          <ul class="flinks m0px list-unstyled fr">
            <li><a href="#">Terms of Use</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div>
      </v-footer> -->
    </v-content>
  </div>
</template>
<script>
import api from "@/api";

export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: false
  }),
  methods: {
    logout() {
      api.logoutArranger();
      this.$router.replace("/home");
    }
  }
};
</script>
