<style lang="sass">
$color-pack: false
@import '../assets/style.scss'
</style>

<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <div class="ac">
            <img
              src="../assets/logo.png"
              alt=""
              style="height:86px; width:auto;"
            />
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav>
        <v-list-item link router-link to="/">
          <v-list-item-action>
            <v-icon color="primary" class="icon-dashboard icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Dashboard</v-list-item-content>
        </v-list-item>

        <v-list-item link router-link to="/Client">
          <v-list-item-action>
            <v-icon color="primary" class="icon-apartment icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Arrangers</v-list-item-content>
        </v-list-item>

        <v-list-item link router-link to="/units">
          <v-list-item-action>
            <v-icon color="primary" class="icon-units icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Units</v-list-item-content>
        </v-list-item>

        <v-list-item link router-link to="/contacts">
          <v-list-item-action>
            <v-icon color="primary" class="icon-users icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Contacts</v-list-item-content>
        </v-list-item>

        <!--  <v-list-item link router-link to="/owners">
          <v-list-item-action>
            <v-icon color="primary" class="icon-users icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Owners</v-list-item-content>
        </v-list-item>
-->
        <v-list-item link router-link to="/employees">
          <v-list-item-action>
            <v-icon color="primary" class="icon-users icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Employees</v-list-item-content>
        </v-list-item>

        <v-list-item link router-link to="/invoices">
          <v-list-item-action>
            <v-icon color="primary" class="icon-documents icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Invoices</v-list-item-content>
        </v-list-item>

        <v-list-item link router-link to="/vendors">
          <v-list-item-action>
            <v-icon color="primary" class="icon-users icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Vendors</v-list-item-content>
        </v-list-item>

        <v-list-item link router-link to="/reports">
          <v-list-item-action>
            <v-icon color="primary" class="icon-documents icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>Reports</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="white"
        class="primary--text"
        light
      >
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="light" /> -->
        <!-- <v-toolbar-title>801 Parking</v-toolbar-title> -->
        <ul class="iconList">
          <li>
            <v-btn
              @click.stop="drawer = !drawer"
              color="light"
              depressed
              rounded
              fab
              ><i class="mdi mdi-menu fs24px"></i
            ></v-btn>
          </li>
          <li>
            <v-btn color="light" flat depressed rounded fab>
              <v-badge color="primary">
                <template v-slot:badge>0</template>
                <i class="icon-maintenance fs20px"></i>
              </v-badge>
            </v-btn>
          </li>
          <li>
            <v-btn color="light" flat depressed rounded fab>
              <v-badge color="purple">
                <template v-slot:badge>0</template>
                <i class="icon-reservations fs20px"></i>
              </v-badge>
            </v-btn>
          </li>
          <li>
            <v-btn color="light" flat depressed rounded fab>
              <v-badge color="pink">
                <template v-slot:badge>0</template>
                <i class="icon-inbox_chat fs20px"></i>
              </v-badge>
            </v-btn>
          </li>
        </ul>
        <v-spacer></v-spacer>

        <div class="searchTop mr10px">
          <v-text-field
            single-line
            filled
            rounded
            dense
            hide-details
            label="Search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </div>

        <v-menu :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="userInfoTop primary--text text-left"
              text
              small
            >
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="detail ttn">
                <div class="name fw400 fs16px">Ron Warner</div>
                <div class="role fw400 grey--text">Admin</div>
              </div>
            </v-btn>
          </template>

          <v-card max-width="200" class="text-center">
            <v-card-text class="text-center">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="hdrUsername mt10px mb10px">
                Hello <span class="blue--text">Ron Warner</span>
              </div>
              <v-btn color="error" depressed rounded small class="ttn"
                >Logout</v-btn
              >
            </v-card-text>
          </v-card>
        </v-menu>
      </v-app-bar>

      <div class="dib100 p30px mainContent">
        <slot />
      </div>

      <v-footer color="white" app>
        <div class="dib100 fs14px">
          <span class="gray--text fl"
            >Copyrights &copy; 2018-2019
            <span class="primary--text">aXessPoint</span> All rights
            reserved</span
          >
          <ul class="flinks m0px list-unstyled fr">
            <li><a href="#">Terms of Use</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div>
      </v-footer>
    </v-content>
  </div>
</template>
<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: true
  })
};
</script>
