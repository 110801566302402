<template>
  <v-card width="100%" min-height="600">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>
    <v-card-title>
      <h4>LOT:{{ obj.lot_number }} BROKERAGE RECORDS</h4>
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="my-2" @click="download">
        <v-btn depressed color="success" dark class="ttn">
          <v-icon class="mr5px">mdi-file-excel</v-icon> Download
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="arrHeaders" :items="arrItems" :search="search">
        <template v-slot:item.lot_id="{ item }">
          <router-link :to="'lots/' + item.lot_id">{{
            item.lot.lot_number
          }}</router-link>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link :to="getRecordLink(item)">Record </router-link>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "Arrangers",
  props: ["lotId", "view"],

  data() {
    return {
      search: "",
      arrHeaders: [
        {
          text: "id",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "ARNCode",
          align: "left",
          sortable: true,
          value: "arn_code"
        },
        {
          text: "Arranger",
          align: "left",
          sortable: true,
          value: "arranger_name"
        },
        {
          text: "ApplNo",
          align: "left",
          sortable: true,
          value: "appl_no"
        },
        {
          text: "EForm",
          align: "left",
          sortable: true,
          value: "e_form"
        },
        {
          text: "CreditDate",
          align: "left",
          sortable: true,
          value: "credit_date_fmt"
        },
        {
          text: "ApplName",
          align: "left",
          sortable: true,
          value: "appl_name"
        },
        {
          text: "Bonds",
          align: "left",
          sortable: true,
          value: "bonds"
        },
        {
          text: "AmountAllotment",
          align: "left",
          sortable: false,
          value: "amount_allotment_fmt"
        },
        {
          text: "SubBrokerCode",
          align: "left",
          sortable: true,
          value: "sub_broker_code"
        },
        {
          text: "DDCHQNUM",
          align: "left",
          sortable: true,
          value: "dd_chq_no"
        },
        {
          text: "PaymentDate",
          align: "left",
          sortable: true,
          value: "payment_date_fmt"
        },
        {
          text: "PanNo",
          align: "left",
          sortable: true,
          value: "pan_no"
        },

        {
          text: "ChequeNo",
          align: "left",
          sortable: true,
          value: "cheque_no"
        },

        {
          text: "Pincode",
          align: "left",
          sortable: true,
          value: "pincode"
        },

        {
          text: "LFolio",
          align: "left",
          sortable: true,
          value: "l_folio"
        }
      ],
      loading: true,
      arrItems: [],

      obj: {},
      dialog: false,
      arrBreadcrumbs: []
    };
  },
  async created() {
    await this.refresh();

    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Lots",
        disabled: false,
        to: `/${this.view}/lots`
      },
      {
        text: "Lot:" + this.obj.lot_number,
        disabled: true
      }
    ];
  },
  methods: {
    download() {
      const strFileName = "Lot" + this.obj.lot_number + "BrokerageRecords";
      //console.log("arrHeaders", this.arrHeaders);
      let arrXLSHeaders = this._getXLSHeaders(this.arrHeaders);
      this.downloadXLS(this.arrItems, arrXLSHeaders, strFileName);
    },
    getRecordLink(objItem) {
      const lotId = this.obj.id;
      const id = objItem.id;
      const str = `/${this.view}/lots/${lotId}/brokerage-records/${id}`;
      return str;
    },

    getViewLink(objItem) {
      return objItem.UnitAddress.OneLine;
    },
    async refresh() {
           this.loading = true;
      try {
        let intLotId = this.lotId;
        let objLot;
        if (this.view == "arranger") {
          objLot = await api.getArrangerLotBrokerageRecords(intLotId);
        } else {
          objLot = await api.getAdminLotBrokerageRecords(intLotId);
        }
        this.obj = objLot;
        this.arrItems = objLot.brokerage_records;

        if (this.view == "arranger") {
          this.arrItems = this.arrItems.map(objItem => {
            objItem.arranger_name = "n/a";
            try {
              objItem.arranger_name = objItem.arranger.name;
            } catch (e) {
              console.log(e);
            }

            return objItem;
          });
        } else if (this.view == "admin") {
          this.arrItems = this.arrItems.map(objItem => {
            objItem.arranger_name = "n/a";
            try {
              objItem.arranger_name = objItem.arranger.name;
            } catch (e) {
              console.log(e);
            }

            return objItem;
          });
        }

        this.arrItems = this.arrItems.map(objItem => {
          objItem = this.formatBrokerageRecord(objItem);
          return objItem;
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
