<!-- this page basically shows all summary records for which bills have 
been uploaded -->
<template>
  <v-card width="100%" min-height="400">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
    </template>
    <v-snackbar v-model="blnSnackbar">
      {{ strSnackbarText }}
    </v-snackbar>
    <template>
      <div>
        <v-breadcrumbs :items="arrBreadcrumbs">
          <template v-slot:item="props">
            <v-breadcrumbs-item
              :to="props.item.to"
              :class="[props.item.disabled]"
              exact
            >
              {{ props.item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </template>

    <v-card-title>
      <h4>BILLS</h4>
      <!-- {{ arrStatus }} -->
      <v-spacer></v-spacer>

      <div class="tableSearch mr10px">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="my-2" @click="download">
        <v-btn depressed color="success" dark class="ttn">
          <v-icon class="mr5px">mdi-file-excel</v-icon> Download
        </v-btn>
      </div>
    </v-card-title>

    <div class="dib100 pb16px">
      <div class="dib pl16px pr16px">
        <v-checkbox
          label="All"
          @change="handleAllChecked"
          v-model="arrAllChecked"
          value="AllChecked"
          hide-details
          class="mt0px"
        ></v-checkbox>
      </div>
      <div class="dib pl16px pr16px">
        <v-checkbox
          v-model="arrCheckedStatus"
          value="N"
          label="New"
          @change="handleStatusChange"
          hide-details
          class="mt0px"
        ></v-checkbox>
      </div>
      <div class="dib pl16px pr16px">
        <v-checkbox
          v-model="arrCheckedStatus"
          value="R"
          label="Rejected"
          @change="handleStatusChange"
          hide-details
          class="mt0px"
        ></v-checkbox>
      </div>
      <div class="dib pl16px pr16px">
        <v-checkbox
          v-model="arrCheckedStatus"
          value="A"
          label="Accepted"
          @change="handleStatusChange"
          hide-details
          class="mt0px"
        ></v-checkbox>
      </div>
      <div class="dib pl16px pr16px">
        <v-checkbox
          value="P"
          v-model="arrCheckedStatus"
          label="Paid"
          @change="handleStatusChange"
          hide-details
          class="mt0px"
        ></v-checkbox>
      </div>
    </div>

    <v-data-table :headers="arrHeaders" :items="arrItems2" :search="search">
      <template v-slot:item.Bill_xxx="{ item }">
        <a target="_blank" :href="getBillLink(item)">Bill</a>
      </template>
      <template v-slot:item.Transactions="{ item }">
        <router-link
          :to="
            'lots/' +
              item.lot_id +
              '/arranger/' +
              item.arranger_id +
              '/summary-records'
          "
          >Brokerwise</router-link
        >
      </template>

      <template v-slot:item._process_xxx="{ item }">
        <v-btn depressed color="secondary" small @click="showBillingForm(item)">Process</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="blnShowForm" persistent max-width="600px">
      <div class="closeBtnDialog"><v-btn color="error" depressed text fab small dark @click="closeForm"><v-icon>mdi-close</v-icon></v-btn></div>
      <BillProcessForm
        v-if="blnShowForm"
        @saved="handleBillSave"
        :view="view"
        :objSummaryRecord="objSummaryRecord"
      ></BillProcessForm>
    </v-dialog>
  </v-card>
</template>

<script>
import api from "@/api";
import BillProcessForm from "@/components/BillProcessForm.vue";

export default {
  name: "AdminBills",
  props: ["view"],
  components: {
    BillProcessForm
  },

  data() {
    let arrStatus = ["N", "R", "A", "P"];
    return {
      arrStatus: arrStatus,
      arrCheckedStatus: [...arrStatus],
      arrAllChecked: ["AllChecked"],
      search: "",
      blnShowForm: false,
      loading: true,
      arrItems: [],
      arrXLSItems: [],
      model: {},
      dialog: false,
      arrBreadcrumbs: [],
      arrHeaders: [
        {
          text: "Lot",
          align: "left",
          sortable: true,
          value: "lot_number"
        },
        {
          text: "Arranger",
          align: "left",
          sortable: true,
          value: "arranger_name"
        },
        /* {
        text: 'DataShareDate',
        align: 'left',
        sortable: true,
        value: 'createdAt'
      },
      {
        text: 'Transactions',
        align: 'left',
        sortable: true,
        value: 'Transactions'
      },*/
        {
          text: "Alloted",
          align: "left",
          sortable: true,
          value: "bonds_alloted"
        },
        {
          text: "BondsAmount",
          align: "left",
          sortable: true,
          value: "amount_on_application_fmt"
        },
        {
          text: "GrossBrokerage",
          align: "left",
          sortable: true,
          value: "gross_brokerage_fmt"
        },
        {
          text: "Incentive",
          align: "left",
          sortable: true,
          value: "incentive_below_250cr_fmt"
        },
        {
          text: "GST",
          align: "left",
          sortable: true,
          value: "gst"
        },
        {
          text: "NetBrokerage",
          align: "left",
          sortable: true,
          value: "net_brokerage_fmt"
        },
        {
          text: "TotalBillAmt",
          align: "left",
          sortable: true,
          value: "_total_bill_amount_fmt"
        },
        {
          text: "BillNumber",
          align: "left",
          sortable: true,
          value: "_bill_number"
        },
        {
          text: "Bill",
          align: "left",
          sortable: true,
          value: "Bill_xxx"
        },
        {
          text: "BillStatus",
          align: "left",
          sortable: true,
          value: "_bill_status_fmt"
        },
        {
          text: "Process",
          align: "left",
          sortable: true,
          value: "_process_xxx"
        },
        {
          text: "UploadDate",
          align: "left",
          sortable: true,
          value: "_bill_upload_date_fmt"
        }
      ]
    };
  },
  computed: {
    arrItems2() {
      return this.arrItems.filter(objItem =>
        this.arrCheckedStatus.includes(objItem._bill_status)
      );
    }
  },
  async created() {
    this.refresh();

    this.arrBreadcrumbs = [
      {
        text: "Home",
        disabled: false,
        to: `/${this.view}/home`
      },
      {
        text: "Bills",
        disabled: true
      }
    ];
  },
  methods: {
    download() {
      const strFileName = "Bills";
      let arrXLSHeaders = this._getXLSHeaders(this.arrHeaders);

      this.downloadXLS(this.arrXLSItems, arrXLSHeaders, strFileName);
    },
    handleAllChecked() {
      if (this.arrAllChecked.length == 1) {
        this.arrCheckedStatus = this.arrStatus;
      } else {
        this.arrCheckedStatus = [];
      }
    },
    handleStatusChange() {
      this.arrAllChecked = [];
    },
    closeForm() {
      this.blnShowForm = false;
    },
    isBillUploaded(obj) {
      let objSummaryRecord = obj.summary_records[0];
      if (objSummaryRecord._bill_number) {
        return true;
      }
      return false;
    },
    showBillingForm(obj) {
      this.objSummaryRecord = { ...obj };
      this.blnShowForm = true;
    },
    handleBillSave() {
      this.showSnackbar("Bill Status Updated");
      this.blnShowForm = false;
      this.refresh();
    },
    getBillLink(obj) {
      let strUrl = api.getServerUrl();
      if (obj._bill_filename) {
        return strUrl + "uploads/" + obj._bill_filename;
      }
      return "";
    },
    get(obj, strProperty) {
      return obj[strProperty];
    },
    getArranger(obj, strProperty) {
      const objArranger = obj.arranger;
      if (objArranger) {
        return objArranger[strProperty];
      }
      return "n/a";
    },
    getLot(obj, strProperty) {
      const objLot = obj.lot;
      if (objLot) {
        return objLot[strProperty];
      }
      return "n/a";
    },
    async refresh() {
           this.loading = true;
      try {
        if (this.view == "admin") {
          let obj = await api.getAdminBills();
          this.arrItems = obj.arr;
        } else if (this.view == "arranger") {
          return;
        }
        this.arrItems = this.arrItems.map(obj => {
          obj.arranger_name = this.getArranger(obj, "name");
          obj.lot_number = this.getLot(obj, "lot_number");
          return obj;
        });
        this.arrXLSItems = JSON.parse(JSON.stringify(this.arrItems));

        this.arrItems = this.arrItems.map(obj => {
          obj = this.formatBill(obj);
          return obj;
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
