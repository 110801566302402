import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#005bab",
        accent: "#72C564",
        secondary: "#f37221",
        success: "#19BC88",
        info: "#14ABF9",
        warning: "#FFC107",
        error: "#DC1A1A"
      }
    }
  },
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  }
});
